.categiries-banners h5{
    font-size: 18px;
    font-weight: 600;
    padding-top: 11px;
   
}
/* .action-cart-btn{
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    background-color: #3d459a;
    cursor: pointer;
    border: 1px solid #3d459a;
    color:white
} */
/* .action-cart-btn:hover{
    background-color: white;
    border: 1px solid white;
    cursor: pointer;
    color:black
} */

.categiries-banners .image-fluid.cate {
    display: block;
    width: 190px;
    height: 190px;
    border-radius: 50%;
}
.jaggray_0{
    padding-top: 101px;
}
.hvr-shutter-out-horizontal {
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    background-color: white;
    cursor: pointer;
    border: 1px solid #3d459a;
    color:#3d459a;
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    position: relative;
    transition-property: color;
    transition-duration: 0.3s;
    width:95%
}
.hvr-shutter-out-horizontal:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #3d459a;
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.hvr-shutter-out-horizontal:hover{
    color:white
}
.hvr-shutter-out-horizontal:hover:before{
    transform: scaleX(1);
}