.wt-50 {
    height: 83px !important;
    width: 160px;
}

.product-title h2 {
    font-size: 18px;
    font-weight: 600;
}

.product-inventory h6 {
    margin-right: 15px;
}

.pro-price-label {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.price-box .new-price {
    font-size: 16px;
    font-weight: 600;
}

.price-box .old-price {
    font-size: 16px;
    text-decoration: line-through;
    padding-left: 5px;
}

.product-label {
    margin-left: 5px;
}

.product_details_info .product-description {
    margin-top: 10px;
    color: #9a9a9a;
}

.pro-detail-button-0 {
    display: flex;
    gap: 35px;
}

.wish-09 {
    font-size: 14px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    background-color: #3d459a;
    justify-content: center;
    border-radius: 50px;
    color: white;
    cursor: pointer;
    margin-right: 5px;
    height: 40px;
    border: 1px solid #3d459a;
}

.product-sku {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #ededed;
    display: flex;
    align-items: center;
}

.product-sku span {
    margin-left: 15px;
    line-height: 1;
}

.tab-0-active {
    border: 1px solid #ed1c23;
    cursor: pointer;

}
.img__item:hover{
    border: 1px solid #ed1c23;
    cursor: pointer;
}

.presentation {
    justify-content: center;
    display: flex;
    list-style: none;
    column-gap: 80px;
}

.des-0__0 {
    padding: 8px 15px;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    border-radius: 50px;
    text-transform: uppercase;
    color: #949494;
}

.uni-details-hero {
    padding: 40px 20px;
}

.pre-active {
    border: 1px solid black;
    color: black;
    border-radius: 50px;
    cursor: pointer;
}

.des-0__0:hover {
    border: 1px solid black;
    color: black;
    cursor: pointer;
}

.spr-form-input-text {
    border: 1px solid #e5e5e5;
    padding: 8px 10px;
    border-radius: 3px;
    width: 100%;
    outline: 0;
}

.spr-header-title {
    font-size: 21px;
    font-weight: 600;
}

.write_re {
    font-size: 16px;
    color: #3d459a;
    cursor: pointer;
    text-decoration: underline;
}

.write_re__090 {
    font-size: 16px;
    color: #3d459a;
    cursor: pointer;
    border: none;
    background-color: unset !important;
    text-decoration: underline;

}
.re-pro-0-9 h4{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
    position: relative;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    text-align: center;
}