.text_he.carousel-caption h1{
    color: rgb(35, 35, 35);
    font-size: 50px;
    font-weight: 600;
}
.text_he.carousel-caption p{
    font-size: 20px;
    font-weight: 600;
}
.btn.btn-style1{
    padding: 10px 30px;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border: 2px solid #ed1c23;
    background-color:#ed1c23 ;
    margin-top: 40px;
}
.text_he.carousel-caption {
    position: absolute;
    top:12%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align:left;
}
.text_he.second.carousel-caption{
    position: absolute;
    top:12%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align:right;
}
.carousel-control-prev {
    opacity: unset !important; 
}
.carousel-control-next {
    opacity: unset !important; 
}
.hom-banner .carousel-control-prev-icon{
    line-height: 0px;
    margin: 0;
    position: absolute;
    bottom: 50%;
    border-radius: 1px;
    transform: translateY(50%);
    font-size: 20px;
    height: 51px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 2px solid #ed1c23;
    background-color: #ed1c23;
}
.hom-banner .carousel-control-next-icon{
    line-height: 0px;
    margin: 0;
    position: absolute;
    bottom: 50%;
    border-radius: 1px;
    transform: translateY(50%);
    font-size: 20px;
    height: 51px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 2px solid #ed1c23;
    background-color: #ed1c23;
}
.carousel-indicators [data-bs-target] {
    background-color: #ed1c23 !important;
    height: 15px !important;
    width: 15px !important;
    border-radius: 50% !important;
}
.banner-hover {
    position: relative;
    overflow: hidden;
}
.banner-card .banner-content {
    position: absolute;
    bottom: 50%;
    width: 50%;
    transform: translateY(50%);
    left: 30px;
}
.banner-content h3{
    color: rgb(35, 35, 35);
    font-size: 26px;
    font-weight: 600;
    text-transform: capitalize;
}
.common-use{
    padding-top:11px;
    padding-bottom: 40px;
}