.section-ptb {
    padding-top: 80px;
    padding-bottom: 80px;
}
.page-not-found .search-error-wrapper {
    text-align: center;
}
.page-not-found .search-error-wrapper h1 {
    font-size: 30px;
    font-weight: 600;
}
.page-not-found .search-error-wrapper h1 span:nth-child(odd){
    color: #9dcd45;
}
.page-not-found .search-error-wrapper h2 span:nth-child(odd){
    color: #9dcd45;
}
.page-not-found .search-error-wrapper h2 {
    font-size: 50px;
    font-weight: 600;
}