.filter_he{
    padding-top: 30px;
    padding-bottom: 30px;
}
.pages-filter{
    display: flex;
    justify-content: space-between;
}
.multiple-filters.scrollbar{
    list-style: none;
    padding-left: 0px;
}
.size-hero{
    display: flex;
    justify-content: space-between;
    padding-bottom: 7px;
    cursor: pointer;
    color:#6c757d
}
.fill_h {
    color: #333333;
    font-size: 19px;
    font-weight: 600;
}
.filter_pro{
    font-size: 15px;
    color: #5f5f5f;
}
.filter-name label{
    padding-left: 6px;
    font-size: 15px;
}
.product-count {
    border-bottom: 1px solid #5f5f5f  
}
.product-count .css-187mznn-MuiSlider-root{
    
    color: #ed1c23 !important;
}
.css-exkjcx {
  color: #ed1c23 !important;
}
.select-sort{
    color: #6c757d;
    font-size: 15px;
    border: 1px solid black;
    padding:0px;
    border-radius: 5px;
}
.paginationBttns {
    width: 80%;
    height: 40px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    list-style: none;
    margin-top: 30px;
  }
  
  .paginationBttns a {
    text-decoration: none;
    padding: 5px 18px;
    background: rgb(255 200 0);;
    color: #fff !important;
    display: flex;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 5px;
    border-radius: 50%;
    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 10%);
  }
  
  .paginationBttns a:hover {
    background: #df2020;
  }
  .filter_search{
    display: flex;
    justify-content: space-between;
  }
  .search-bar.site-header__search{
    position: relative;
    border: 1px solid #141212;
    height: 42px;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;

  }
  .input-text{
    border: none;
    padding: 4px 10px;
  }
  .search-btn{
    /* border: none; */
    height: 40px;
    padding: 5px;
    width: 40px;
    background: #fdfdfd;
    border: 1px solid #dbd8d8;
    border-radius: 50%
}