.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  overflow-x: hidden;
  width: 100%;
  padding-right: 0px !important;
}
html{
  overflow-x: hidden;
  width: 100%;
}

.header-top {
  /* background-color: #efefef; */
  padding: 10px 0px;
  color: white;
}
.slider img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.category-carousel img {
  width: 100%;
  height: 241px !important;
  object-fit: cover;
  border-radius: 50%;
}
.login {
  padding: 100px 0px;
  background-image: url("");
}
.login a {
  color: white;
}
.login-modal {
  background-color: #b382823b !important;
}
.top-header {
  padding: 1px 20px;
}
/* .header {
  background-color: #d1cece !important;
} */

.custom-sidebar {
  height: 550px !important;
  width: 189px !important;
  min-width: 211px !important;
}
.login-form {
  width: 40%;
  margin: 50px auto;
  padding: 50px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
}
/* .custom-nav {
  background-color: #313d87 !important;
} */
.category-carousel h6 {
  text-align: center;
  padding: 10px;
}
.header-signin {
  color: #ed2025;
  font-weight: 500;
  padding: 28px 22px 17px 6px;
  cursor: pointer;
}
.topdeals-carousel img {
  height: 135px;
  object-fit: cover;
}
.topdeals-carousel .items {
  border: 1px solid #dad4d4;
  padding: 10px 8px;
  border-radius: 11px;
}
.cart-btn {
  background-color: #c54f78 !important;
  color: white !important;
}
.header-top .form-control {
  border: 2px solid #ffffff !important;
  border-radius: 12px !important;
  box-shadow: 0px 0px 4px 1px #bb2d5f;
}
.header-top .form-group {
  padding: 20px 0px 0px;
}
.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}
.footer {
  background-color: rgb(254 200 0);
  padding: 48px 14px 0px;
}
.bottom-footer {
  border-top: 1px solid #cecbcb;
}
ul {
  list-style: none;
  padding-left: 0px !important;
}
.footer h6 {
  font-size: 18px;
  font-weight: 700;
}
.form-group {
  margin-bottom: 15px;
}
.form-group {
  font-weight: 600;
}
.custom-nav .nav-link {
  padding: 0px;
}
.custom-offer .col {
  margin: 20px 0px 20px;
}
.productdetail-img {
  height: 350px !important;
  width: 100% !important;
}
.essential-carousel img {
  height: 242px !important;
}

.custom-nav{
  background-image: linear-gradient(to right, #bf1884 , var(--bs-purple));
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1320px !important;
  }
  .category-container {
    max-width: 1226px !important;
    margin: auto;
  }
}
@media screen and (max-width: 767px) {
  .category-carousel img {
    height: 192px !important;
  }
}
.allcategory img {
  height: 230px;
  object-fit: cover;
}
.makeStyles-drawerHeader-8 {
  background: #aab2e3 !important;
}
.home-local {
  box-shadow: 0px 1px 5px 1px #bc3364;
  text-align: center;
}

.home-local .card-title {
  color: #313d87;
  font-weight: bold;
}
.r-img{
  border-radius: 149px !important;
}
.button{
  background-color: white !important;
  border:1px solid white !important;
}
.bg:active{
  background: red;
}

.my-modal .modal-content {
  background-color: none !important;
  width: 560px !important;
  left: -133px !important;
}
.selected{
  border: 1px solid red;
}
.head{

  font-weight: 500;
  padding: 7px 7px 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  margin-left: 60px;
}
.border img{
  width: 100% !important;

}

@media screen and (max-width: 767px) {
  .head{
    margin-left: 0px !important;
  }
  .my-modal .modal-content {
    /* background-color: none !important; */
    width: 0px !important;
    left: 0px !important;
  }
  .form-control{
    width:135px !important;
    margin-left: -14px !important;
  }
}
.content{
    position: fixed;
    background: red;
    right: -8px;
    bottom: 63px;
    border-radius: 24px;
    z-index: 1000;
}
.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  width: max-content!important;
}
.goog-te-gadget .goog-te-combo {
  margin: 4px 0;
  padding: 9px;
  border: none;
  font-size: medium;
}
.goog-te-gadget {
  font-family: arial;
  font-size: 11px;
  color: #666;
  white-space: nowrap;
  display: grid;
}
.goog-te-banner-frame.skiptranslate {
  display: none;
}
.makeStyles-drawerHeader-8 {
  color: white;
  display: flex;
  padding: 0px 8px;

  min-height: 56px;
  align-items: center;
  justify-content: flex-start!important;
}
/* -----------------------------------------sam---------------------------------------------- */
.admin-bg{
/* background-color: #2e3da0ad; */
margin: 0em 10em;
}
/* .header{
  background-color: #2e3da0ad !important;
} */
.close{
  background-color: #2e3da0ad; 
}
.pro-sidebar > .pro-sidebar-inner {
  background: rgb(254 200 3);
}
.pro-item-content{
  color: white !important;
  font-size: 20px !important;
  font-weight: 700;
}
.pro-arrow-wrapper{
  color: white;
  font-size: 20px !important;
  font-weight: 400;
}
.end{
  display: flex;
  justify-content: end;
}
/* ============================================================== */
.i-con{
  font-size: 7rem;
  color: white;
  padding: 10px;
}
.red{
  background: #B9231F;
  padding: 20px;
  width: 10rem;
  margin: 2%;
}
.d-text{
  color: white;
  text-align: center;
}
.center{
  display: flex;
  justify-content: center;
}
.background{
  background: #E9F0F9;
  color: #E9F0F9;
  width: 100%;
  height: 100%;
}
.green{
 background: #31A221;
 padding: 20px;
 width: 10rem;
 margin: 2%;
}
.pink{
  background: #C7266C;
  padding: 20px;
  width: 10rem;
  margin: 2%;
}
.blue{
  background: #3AB2DB;
  padding: 20px;
  width: 10rem;
  margin: 2%;
}
.orange{
  background: #E79712;
  padding: 20px;
  width: 10rem;
  margin: 2%;
}
.darkb{
  background: #381095;
  padding: 20px;
  width: 10rem;
  margin: 2%;
}
.grid6{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
}
.wred{
  background: #B9231F;
  padding: 20px;
  width: 14rem;
  margin: 2%;
}
.wdarkb{
  background: #381095;
  padding: 20px;
  width: 14rem;
  margin: 2%;
}
.wgreen{
  background: #31A221;
  padding: 20px;
  width: 14rem;
  margin: 2%;
}
.worange{
  background: #E79712;
  padding: 20px;
  width: 14rem;
  margin: 2%;
}
.count{
  color: white;
  text-align: center;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  display: inline-block;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 4px 4px 0 !important;
  padding: 2.5px;
  font-weight: bold !important;
  vertical-align: middle;
  transition: transform 0.3s;
  transform: rotate(-45deg);
}
.ant-switch.jdsvljds.css-dev-only-do-not-override-mxhywb.ant-switch-checked{
  background: #dc3545 !important;
}
.delete-button{
  color: #b91f74;
  font-size: 30px;

}
.end{
  display: flex;
  justify-content: end;
}
.view-button{
  color: #313d87;
  font-size: 30px;
}
