@import url(https://fonts.googleapis.com/css2?family=Aclonica&family=Cairo:wght@200;300;400;500;600;700;800;900&family=Kanit:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oswald:wght@200;300;400;500;600&family=Poppins:ital,wght@0,100;0,800;0,900;1,100;1,800&family=Roboto:ital,wght@0,700;0,900;1,100;1,500&display=swap);
*{
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  font-family: "Cairo",sans-serif;
}
iframe{
  /* position: fixed !important; */
  /* height:unset !important; */
  top: unset !important;
  left:unset !important;
}

.single-service li {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.single-service li span {
    font-size: 18px;
    width: calc(100% - 115px);
    color: white;
    font-weight: 600;
}

.single-service li a {
    position: relative;
    z-index: 0;
    margin-right: 15px;
    line-height: 0;
    font-size: 30px;
    color: #fff;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #ed1c23;
    background-color: #ed1c23;
}

.bottom_0 {
    border-bottom: 1px solid #ed1c23
}

.logo p {
    font-size: 17px;
    display: block;
    color: white;
    font-weight: 600;
}

.address-icon {
    display: flex;
    grid-gap: 25px;
    gap: 25px
}

.address-icon span i {
    font-size: 32px;
    color: #ed1c23;
}

.address-icon h6 {
    font-size: 20px;
    font-weight: 600;
    color: #ed1c23;
}

.address-icon p {
    font-size: 17px;
    display: block;
    color: white;
    font-weight: 600;
}

.address-icon .jgp {
    font-size: 17px;
    display: block;
    color: white;
    font-weight: 600;
}

.social {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sociale_icons ul {
    display: flex;
    list-style: none;
    margin-top: 10px;
    margin-bottom: 0px;
}
.social_0{
    margin-top: 20px;
    padding: 0px 21px;
}

.sociale_icons ul li {
    margin: 0px 12px;
    border: 1px solid #ed1c23;
    padding: 10px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(254 200 0);
}

.sociale_icons ul li i {
    color: white;
    font-size: 25px

}

.diff {
    background-color: #ed1c23;
}
.sociale_icons ul li:hover{
    border: 1px solid white;
    background-color: white;
    transform: translateY(-5px);
    transition: all .2s ease-in-out ;
}
.sociale_icons ul li i:hover {
   color:#ed1c23
}
.play_0{
    grid-gap: 10px;
    gap: 10px;
    display: flex;
}

.text_he.carousel-caption h1{
    color: rgb(35, 35, 35);
    font-size: 50px;
    font-weight: 600;
}
.text_he.carousel-caption p{
    font-size: 20px;
    font-weight: 600;
}
.btn.btn-style1{
    padding: 10px 30px;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border: 2px solid #ed1c23;
    background-color:#ed1c23 ;
    margin-top: 40px;
}
.text_he.carousel-caption {
    position: absolute;
    top:12%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align:left;
}
.text_he.second.carousel-caption{
    position: absolute;
    top:12%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align:right;
}
.carousel-control-prev {
    opacity: unset !important; 
}
.carousel-control-next {
    opacity: unset !important; 
}
.hom-banner .carousel-control-prev-icon{
    line-height: 0px;
    margin: 0;
    position: absolute;
    bottom: 50%;
    border-radius: 1px;
    transform: translateY(50%);
    font-size: 20px;
    height: 51px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 2px solid #ed1c23;
    background-color: #ed1c23;
}
.hom-banner .carousel-control-next-icon{
    line-height: 0px;
    margin: 0;
    position: absolute;
    bottom: 50%;
    border-radius: 1px;
    transform: translateY(50%);
    font-size: 20px;
    height: 51px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 2px solid #ed1c23;
    background-color: #ed1c23;
}
.carousel-indicators [data-bs-target] {
    background-color: #ed1c23 !important;
    height: 15px !important;
    width: 15px !important;
    border-radius: 50% !important;
}
.banner-hover {
    position: relative;
    overflow: hidden;
}
.banner-card .banner-content {
    position: absolute;
    bottom: 50%;
    width: 50%;
    transform: translateY(50%);
    left: 30px;
}
.banner-content h3{
    color: rgb(35, 35, 35);
    font-size: 26px;
    font-weight: 600;
    text-transform: capitalize;
}
.common-use{
    padding-top:11px;
    padding-bottom: 40px;
}
.categiries-banners h5{
    font-size: 18px;
    font-weight: 600;
    padding-top: 11px;
   
}
/* .action-cart-btn{
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    background-color: #3d459a;
    cursor: pointer;
    border: 1px solid #3d459a;
    color:white
} */
/* .action-cart-btn:hover{
    background-color: white;
    border: 1px solid white;
    cursor: pointer;
    color:black
} */

.categiries-banners .image-fluid.cate {
    display: block;
    width: 190px;
    height: 190px;
    border-radius: 50%;
}
.jaggray_0{
    padding-top: 101px;
}
.hvr-shutter-out-horizontal {
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    background-color: white;
    cursor: pointer;
    border: 1px solid #3d459a;
    color:#3d459a;
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    position: relative;
    transition-property: color;
    transition-duration: 0.3s;
    width:95%
}
.hvr-shutter-out-horizontal:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #3d459a;
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.hvr-shutter-out-horizontal:hover{
    color:white
}
.hvr-shutter-out-horizontal:hover:before{
    transform: scaleX(1);
}
.categiries-banner {
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.banner-slider {
  background-color: #f7f7f7;
  padding: 50px 0px;
}

.image-fluid.cate {
  display: block;
  width: 190px;
  height: 190px;
  border-radius: 50%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #13181a8f;
}

.categiries-banner:hover .overlay {
  opacity: 1;
  cursor: pointer;
}

.text {
  color: white;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Poppins", sans-serif;
  transform: translate(-50%, -50%);
  text-align: center;
}

.react-multi-carousel-item {
  display: flex;
  justify-content: center;
}

.react-multiple-carousel__arrow {
  background: #ed1c23 !important;
  height: 30px !important;
  width: 30px !important;
  z-index: 1 !important;
}

.single-product-wrap .product-image {
  position: relative;
  cursor: pointer;
}

.single-product-wrap .product-image .product-label .sale-title {
  position: absolute;
  top: 5px;
}

.single-product-wrap .product-image .product-label .sale-title {
  right: 5px;
}

.product-label .sale-title {
  background-color: #ed1c23;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20% 50%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20% 50%);
}

.product-label .sale-title {
  font-size: 13px;
  padding: 2px 5px 2px 15px;
  color: #fff;
}

.single-product-wrap .product-image .product-action {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}

.action-wishlist {
  display: flex;
  flex-direction: row;
}

.add-wishlist {
  opacity: 0;
  font-size: 16px;
  line-height: 0;
  height: 40px;
  width: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin-right: 15px;
}

.product-image:hover .add-wishlist {
  opacity: 1;
  cursor: pointer;
}

.product-content .product-title a {
  font-weight: 600;
  color: black;
  text-decoration: none;
}

.new-price {
  font-size: 16px;
  font-weight: 600;
}

.old-price {
  font-size: 16px;
  text-decoration: line-through;
  padding-left: 5px;
}

/* .single-product-wrap {
  box-shadow: rgb(1 164 85 / 20%) 0px 0px 5px 0px,
    rgb(1 162 82 / 39%) 0px 0px 1px 0px;
  border-radius: 5px;
} */
.single-product-wrap:hover{
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;	
}

.product-content {
  padding: 0px 10px 5px;
}

.product_hero ul {
  display: flex;
  list-style: none;
  justify-content: center;
}

.product_hero ul li {
  margin-right: 30px;
  padding: 8px 15px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #949494;
}

.product_hero ul li:hover {
  border: 1px solid #3d459a;
  background-color: #3d459a;
  color: white;
  border-radius: 50px;
  cursor: pointer;
}

.active-tab {
  border: 1px solid #3d459a;
  color: white !important;
  border-radius: 50px;
  cursor: pointer;
  background-color: #3d459a;
}

.pro-quick {
  display: flex;
}

.pro-quick .quick-view {
  width: 50%;
}

.ant-modal {
  width: 651px !important;
}

.hero_quick .product-title a {
  font-size: 16px;
  color: #333333;
}

.more-de .simple-0 {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.more-de .lorem-09 {
  color: #8e8e8e;
}

.selector-wrapper label {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.single-option-selector {
  margin-left: 8px;
  padding: 8px 30px 8px 15px;
  border: 1px solid #eeeeee;
  border-radius: 50px;
  text-transform: uppercase;
}

.cart-plus-minus {
  max-width: 112px;
  display: flex;
  border: 1px solid #eee;
  width: 105px;
}

.dec.qtybutton {
  height: 30px;
  width: 30px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inc.qtybutton {
  height: 30px;
  width: 30px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.cart-plus-minus input{
  height: 30px;
    width: 50px;
    font-size: 13px;
    padding: 0;
    text-align: center;
    border: none;
    border-radius: 0;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    outline-width: 0px;
}
.product-action-2 .action-wishlist .add-wishlist{
  opacity: 1;
    background: #ed1c23;
    color: white;
  font-size: 16px;
    line-height: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
}
 .ant-modal-footer{
  display: none!important;
}
.ant-modal-header{
  border-bottom: 1px solid #8f8f8f8f;
}
/******************************************************************
  Template Name: Ogani
  Description:  Ogani eCommerce  HTML Template
  Author: Colorlib
  Author URI: https://colorlib.com
  Version: 1.0
  Created: Colorlib
******************************************************************/

/*------------------------------------------------------------------
[Table of contents]

1.  Template default CSS
	1.1	Variables
	1.2	Mixins
	1.3	Flexbox
	1.4	Reset
2.  Helper Css
3.  Header Section
4.  Hero Section
5.  Service Section
6.  Categories Section
7.  Featured Section
8.  Latest Product Section
9.  Contact
10.  Footer Style
-------------------------------------------------------------------*/

/*----------------------------------------*/
/* Template default CSS
/*----------------------------------------*/

html,
body {
	height: 100%;
	font-family: "Cairo", sans-serif;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}
.categories__item.set-bg:hover{
	box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.item{
	padding: 10px;
}
.latest-prdouct__slider__item a{
	text-decoration: none;
}
.section-title h2 a:hover{
	color:#3CC032;
	text-decoration: underline;
}
.section-title h2 a{
	text-decoration: none;
}
.amazon-0{
    padding: 5px;
}
.amazon-0 span{
	font-size: 14px;
    color: white;
    background-color: #ed1c23;
    padding: 0px 6px;
   

}
.spin {
	animation: spin 4s infinite linear;
  }
  @keyframes spin {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
  }
.amazon-0 p{
    font-size: 14px;
    color: white;
    background-color: #ed1c23;
	padding-right:8px
}
.latest-product__item__pic img{
	border-radius: 50%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	color: #111111;
	font-weight: 400;
	font-family: "Cairo", sans-serif;
}

h1 {
	font-size: 70px;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

p {
	font-size: 16px;
	font-family: "Cairo", sans-serif;
	color: #6f6f6f;
	font-weight: 400;
	line-height: 26px;
	margin: 0 0 15px 0;
}

/* img {
	max-width: 100%;
} */

input:focus,
select:focus,
button:focus,
textarea:focus {
	outline: none;
}

a:hover,
a:focus {
	text-decoration: none;
	outline: none;
	color: #ffffff;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

/*---------------------
  Helper CSS
-----------------------*/

.section-title {
	margin-bottom: 23px;
	text-align: center;
}

.section-title h2 {
	color: #1c1c1c;
	font-weight: 700;
	position: relative;
}

.section-title h2:after {
	position: absolute;
	left: 0;
	bottom: -15px;
	right: 0;
	height: 4px;
	width: 80px;
	background: #ed1c23;
	content: "";
	margin: 0 auto;
}

.set-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
}

.spad {
	padding-top: 100px;
	padding-bottom: 100px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
	color: #fff;
}

/* buttons */

.primary-btn {
	display: inline-block;
	font-size: 14px;
	padding: 10px 28px 10px;
	color: #ffffff;
	text-transform: uppercase;
	font-weight: 700;
	background: #ed1c23;
	letter-spacing: 2px;
}

.site-btn {
	font-size: 14px;
	color: #ffffff;
	font-weight: 800;
	text-transform: uppercase;
	display: inline-block;
	padding: 13px 30px 12px;
	background: #ed1c23;
	border: none;
}

/* Preloder */

#preloder {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999999;
	background: #000;
}

.loader {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -13px;
	margin-left: -13px;
	border-radius: 60px;
	animation: loader 0.8s linear infinite;
	-webkit-animation: loader 0.8s linear infinite;
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
	50% {
		transform: rotate(180deg);
		border: 4px solid #673ab7;
		border-left-color: transparent;
	}
	100% {
		transform: rotate(360deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
}

/*---------------------
  Header
-----------------------*/

.header__top {
	background: rgb(254 200 3);
    height: 51px;
}

.header__top__left {
	padding: 10px 0 13px;
}

.header__top__left ul li {
	font-size: 14px;
	color: white;
	display: inline-block;
	margin-right: 45px;
	position: relative;
}

.header__top__left ul li:after {
	position: absolute;
	right: -25px;
	top: 1px;
	height: 20px;
	width: 1px;
	background: #ffff;
	opacity: 0.1;
	content: "";
}

.header__top__left ul li:last-child {
	margin-right: 0;
}

.header__top__left ul li:last-child:after {
	display: none;
}

.header__top__left ul li i {
	color: #fff;
	margin-right: 5px;
}

.header__top__right {
	text-align: right;
	padding: 10px 0 13px;
}

.header__top__right__social {
	position: relative;
	display: inline-block;
	margin-right: 35px;
}

.header__top__right__social:after {
	position: absolute;
	right: -20px;
	top: 1px;
	height: 20px;
	width: 1px;
	background: #fff;
	opacity: 0.1;
	content: "";
}

.header__top__right__social a {
	font-size: 14px;
	display: inline-block;
	color: #fff;
	margin-right: 20px;
}

.header__top__right__social a:last-child {
	margin-right: 0;
}

.header__top__right__language {
	position: relative;
	display: inline-block;
	margin-right: 40px;
	cursor: pointer;
}

.header__top__right__language:hover ul {
	top: 23px;
	opacity: 1;
	visibility: visible;
}

.header__top__right__language:after {
	position: absolute;
	right: -21px;
	top: 1px;
	height: 20px;
	width: 1px;
	background: #fff;
	opacity: 0.1;
	content: "";
}

.header__top__right__language img {
	margin-right: 6px;
}

.header__top__right__language div {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	margin-right: 4px;
}

.header__top__right__language span {
	font-size: 14px;
	color: #1c1c1c;
	position: relative;
	top: 2px;
}

.header__top__right__language ul {
	background: rgb(254 200 0);
	width: 100px;
	text-align: left;
	padding: 5px 0;
	position: absolute;
	left: 0;
	top: 43px;
	z-index: 9;
	opacity: 0;
	visibility: hidden;
	transition: all, 0.3s;
}

.header__top__right__language ul li {
	list-style: none;
}

.header__top__right__language ul li a {
	font-size: 14px;
	color: #ffffff;
	padding: 5px 10px;
}

.header__top__right__auth {
	display: inline-block;
}

.header__top__right__auth a {
	display: block;
	font-size: 14px;
	color: #fff;
}

.header__top__right__auth a i {
	margin-right: 6px;
}

.header__logo {
	padding: 15px 0;
}

.header__logo a {
	display: inline-block;
}

.header__menu {
	padding: 24px 0;
}

.header__menu ul li {
	list-style: none;
	display: inline-block;
	/* margin-right: 20px; */
	position: relative;
}

.header__menu ul li .header__menu__dropdown {
	position: absolute;
	left: 0;
	top: 50px;
	background: rgb(254 200 0);
	width: 180px;
	z-index: 9;
	padding: 5px 0;
	transition: all, 0.3s;
	opacity: 0;
	visibility: hidden;
}

.header__menu ul li .header__menu__dropdown li {
	margin-right: 0;
	display: block;
}

.header__menu ul li .header__menu__dropdown li:hover>a {
	color: #ed1c23;
}

.header__menu ul li .header__menu__dropdown li a {
	text-transform: capitalize;
	color: #ffffff;
	font-weight: 400;
	padding: 5px 15px;
}

.header__menu ul li.active a {
	color: white;
    background:rgb(254 200 0) ;
}

.header__menu ul li:hover .header__menu__dropdown {
	top: 30px;
	opacity: 1;
	visibility: visible;
}

.header__menu ul li:hover>a {
	color: #ed1c23;
}

.header__menu ul li:last-child {
	margin-right: 0;
}

.header__menu ul li a {
	font-size: 14px;
	color: #252525;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 2px;
	transition: all, 0.3s;
	padding: 5px 13px;
	display: block;
	text-decoration: none;
}

.header__cart {
	text-align: right;
	padding: 24px 0;
}

.header__cart ul {
	display: inline-block;
	margin-right: 25px;
}

.header__cart ul li {
	list-style: none;
	display: inline-block;
	margin-right: 15px;
}

.header__cart ul li:last-child {
	margin-right: 0;
}

.header__cart ul li a {
	position: relative;
}

.header__cart ul li a i {
	font-size: 18px;
	color: #1c1c1c;
}

.header__cart ul li a span {
	height: 13px;
	width: 13px;
	background: #ed1c23;
	font-size: 10px;
	color: #ffffff;
	line-height: 13px;
	text-align: center;
	font-weight: 700;
	display: inline-block;
	border-radius: 50%;
	position: absolute;
	top: 0;
	right: -12px;
}

.header__cart .header__cart__price {
	font-size: 14px;
	color: #6f6f6f;
	display: inline-block;
}

.header__cart .header__cart__price span {
	color: #252525;
	font-weight: 700;
}

.humberger__menu__wrapper {
	display: none;
}

.humberger__open {
	display: none;
}

/*---------------------
  Hero
-----------------------*/

.hero {
	padding-bottom: 7px;
}

.hero.hero-normal {
	padding-bottom: 30px;
}

.hero.hero-normal .hero__categories {
	position: relative;
}

.hero.hero-normal .hero__categories ul {
	display: none;
	position: absolute;
	left: 0;
	top: 46px;
	width: 100%;
	z-index: 9;
	background: #ffffff;
}

.hero.hero-normal .hero__search {
	margin-bottom: 0;
}

.hero__categories__all {
	background: #ed1c23;
	position: relative;
	padding: 10px 25px 10px 40px;
	cursor: pointer;
}

.hero__categories__all i {
	font-size: 16px;
	color: #ffffff;
	margin-right: 10px;
}

.hero__categories__all span {
	font-size: 18px;
	font-weight: 700;
	color: #ffffff;
}

.hero__categories__all:after {
	position: absolute;
	right: 18px;
	top: 9px;
	content: "";
	font-family: "ElegantIcons";
	font-size: 18px;
	color: #ffffff;
}

.hero__categories ul {
	border: 1px solid #ebebeb;
	padding-left: 40px;
	padding-top: 10px;
	padding-bottom: 12px;
	height: 457px;
    overflow: hidden;
    overflow-y: auto;
}

.hero__categories ul li {
	list-style: none;
	padding-left: 40px;
}

.hero__categories ul li a {
	font-size: 17px;
	color: #1c1c1c;
	line-height: 39px;
	display: block;
	text-decoration: none;
	font-weight: 600;
}
.hero__categories ul li a:hover{
	color:#ed1c23;
}
.hero__search {
	overflow: hidden;
	margin-bottom: 30px;
}

.hero__search__form {
	width: 610px;
	height: 50px;
	border: 1px solid #ebebeb;
	position: relative;
	float: left;
}

.hero__search__form form .hero__search__categories {
	width: 30%;
	float: left;
	font-size: 16px;
	color: #1c1c1c;
	font-weight: 700;
	padding-left: 18px;
	padding-top: 11px;
	position: relative;
}

.hero__search__form form .hero__search__categories:after {
	position: absolute;
	right: 0;
	top: 14px;
	height: 20px;
	width: 1px;
	background: #000000;
	opacity: 0.1;
	content: "";
}

.hero__search__form form .hero__search__categories span {
	position: absolute;
	right: 14px;
	top: 14px;
}

.hero__search__form form input {
	width: 70%;
	border: none;
	height: 48px;
	font-size: 16px;
	color: #b2b2b2;
	padding-left: 20px;
}

.hero__search__form form input::placeholder {
	color: #b2b2b2;
}

.hero__search__form form button {
	position: absolute;
	right: 0;
	top: -1px;
	height: 50px;
}

.hero__search__phone {
	float: right;
}

.hero__search__phone__icon {
	font-size: 18px;
	color: #ed1c23;
	height: 50px;
	width: 50px;
	background: #f5f5f5;
	line-height: 50px;
	text-align: center;
	border-radius: 50%;
	float: left;
	margin-right: 20px;
}

.hero__search__phone__text {
	overflow: hidden;
}

.hero__search__phone__text h5 {
	color: #1c1c1c;
	font-weight: 700;
	margin-bottom: 5px;
}

.hero__search__phone__text span {
	font-size: 14px;
	color: #6f6f6f;
}

.hero__item {
	height: 431px;
	display: flex;
	align-items: center;
	padding-left: 75px;
}

.hero__text span {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 4px;
	color: #ed1c23;
}

.hero__text h2 {
	font-size: 46px;
	color: #252525;
	line-height: 52px;
	font-weight: 700;
	margin: 10px 0;
}

.hero__text p {
	margin-bottom: 35px;
}

/*---------------------
  Categories
-----------------------*/

.categories__item {
	height: 270px;
	position: relative;
}

.categories__item h5 {
	position: absolute;
	left: 0;
	width: 100%;
	padding: 0 20px;
	bottom: 20px;
	text-align: center;
}

.categories__item h5 a {
	font-size: 18px;
	color: #1c1c1c;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 2px;
	padding: 12px 0 10px;
	background: #ffffff;
	display: block;
	text-decoration: none;

}

.categories__slider .col-lg-3 {
	max-width: 100%;
}

.categories .owl-carousel .owl-nav .owl-next {
	font-size: 18px;
	color: #ffff;
	height: 70px;
	width: 30px;
	line-height: 70px;
	text-align: center;
	border: 1px solid #ed1c23;
	position: absolute;
	right: -35px;
	top: 50%;
	margin-right: -5px;
	-webkit-transform: translateY(-35px);
	background: #ed1c23;
}
.categories .owl-carousel .owl-nav .owl-prev {
	font-size: 18px;
	color: #ffff;
	height: 70px;
	width: 30px;
	line-height: 70px;
	text-align: center;
	border: 1px solid #ed1c23;
	position: absolute;
	left: -35px;
	top: 50%;
	margin-left: -5px;
	-webkit-transform: translateY(-35px);
	background: #ed1c23;
}


.categories__slider.owl-carousel .owl-nav button.owl-next {
	left: auto;
	right: -35px;
}

/*---------------------
  Featured
-----------------------*/

.featured {
	padding-top: 11px;
	padding-bottom: 40px;
}

.featured__controls {
	text-align: center;
	margin-bottom: 5px;
}

.featured__controls ul li {
	list-style: none;
	font-size: 18px;
	color: #1c1c1c;
	display: inline-block;
	margin-right: 25px;
	position: relative;
	cursor: pointer;
	font-weight: 700;
}
.featured__controls ul li:hover{
	color:#ed1c23
}

.featured__controls ul li.active:after {
	opacity: 1;
}

.featured__controls ul li:after {
	position: absolute;
	left: 0;
	bottom: -2px;
	width: 100%;
	height: 2px;
	background: #ed1c23;
	content: "";
	opacity: 0;
}

.featured__controls ul li:last-child {
	margin-right: 0;
}

.featured__item {
	margin-bottom: 20px;
	
	
}
.rty:hover {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.ghjk:hover{
	box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;	
}
.ghjk-best:hover{
	box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;	
}
.discount:hover{
	box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;	
}

.featured__item:hover .featured__item__pic .featured__item__pic__hover {
	bottom: 20px;
}

.featured__item__pic {
	height: 151px;
	position: relative;
	overflow: hidden;
	background-position: center center;
}

.featured__item__pic__hover {
	position: absolute;
	left: 0;
	bottom: -57px;
	width: 100%;
	text-align: center;
	transition: all, 0.5s;
}

.featured__item__pic__hover li {
	list-style: none;
	display: inline-block;
	margin-right: 6px;
}

.featured__item__pic__hover li:last-child {
	margin-right: 0;
}

.featured__item__pic__hover li:hover a {
	background: #ed1c23;
	border-color: #ed1c23;
}

.featured__item__pic__hover li:hover a i {
	color: #ffffff;
	transform: rotate(360deg);
}

.featured__item__pic__hover li a {
	font-size: 16px;
	color: #1c1c1c;
	height: 40px;
	width: 40px;
	line-height: 40px;
	text-align: center;
	border: 1px solid #ebebeb;
	background: #ffffff;
	display: block;
	border-radius: 50%;
	transition: all, 0.5s;
}

.featured__item__pic__hover li a i {
	position: relative;
	transform: rotate(0);
	transition: all, 0.3s;
}

.featured__item__text {
	text-align: center;
	padding-top: 15px;
}

.featured__item__text h6 {
	margin-bottom: 10px;
}

.featured__item__text h6 a {
	color: #252525;
}

.featured__item__text h5 {
	color: #252525;
	font-weight: 700;
}

/*---------------------
  Latest Product
-----------------------*/

.latest-product {
	padding-top: 5px;
	padding-bottom: 0;
}

.latest-product__text h4 {
	font-weight: 700;
	color: #ed1c23;
	margin-bottom: 22px;
	font-size: 25px;
}

.latest-product__slider .owl-carousel .owl-nav {
	position: absolute;
	right: 20px;
	top: -65px;
}

.latest-product__slider .owl-carousel .owl-nav button {
	height: 30px;
	width: 30px;
	background: #F3F6FA;
	border: 1px solid #e6e6e6;
	font-size: 14px;
	color: #636363;
	margin-right: 10px;
	line-height: 30px;
	text-align: center;
}

.latest-product__slider .owl-carousel .owl-nav button span {
	font-weight: 700;
}

.latest-product__slider .owl-carousel .owl-nav button:last-child {
	margin-right: 0;
}

.latest-product__item {
	margin-bottom: 20px;
	overflow: hidden;
	display: flex;
}

.latest-product__item__pic {
	float: left;
	margin-right: 26px;
}

.latest-product__item__pic img {
	height: 110px;
	width: 110px !important;
}

.latest-product__item__text {
	overflow: hidden;
	padding-top: 10px;
}

.latest-product__item__text h6 {
	color: #252525;
	margin-bottom: 8px;
}

.latest-product__item__text span {
	font-size: 18px;
	display: block;
	color: #252525;
	font-weight: 700;
}

/*---------------------
  Form BLog
-----------------------*/

.from-blog {
	padding-top: 50px;
	padding-bottom: 50px;
}

.from-blog .blog__item {
	margin-bottom: 30px;
}

.from-blog__title {
	margin-bottom: 70px;
}

/*---------------------
  Breadcrumb
-----------------------*/

.breadcrumb-section {
	display: flex;
	align-items: center;
	padding: 45px 0 40px;
}

.breadcrumb__text h2 {
	font-size: 46px;
	color: #ffffff;
	font-weight: 700;
}

.breadcrumb__option a {
	display: inline-block;
	font-size: 16px;
	color: #ffffff;
	font-weight: 700;
	margin-right: 20px;
	position: relative;
}

.breadcrumb__option a:after {
	position: absolute;
	right: -12px;
	top: 13px;
	height: 1px;
	width: 10px;
	background: #ffffff;
	content: "";
}

.breadcrumb__option span {
	display: inline-block;
	font-size: 16px;
	color: #ffffff;
}

/*---------------------
  Sidebar
-----------------------*/

.sidebar__item {
	margin-bottom: 35px;
}

.sidebar__item.sidebar__item__color--option {
	overflow: hidden;
}

.sidebar__item h4 {
	color: #1c1c1c;
	font-weight: 700;
	margin-bottom: 25px;
}

.sidebar__item ul li {
	list-style: none;
}

.sidebar__item ul li a {
	font-size: 16px;
	color: #1c1c1c;
	line-height: 39px;
	display: block;
}

.sidebar__item .latest-product__text {
	position: relative;
}

.sidebar__item .latest-product__text h4 {
	margin-bottom: 45px;
}

.sidebar__item .latest-product__text .owl-carousel .owl-nav {
	right: 0;
}

.price-range-wrap .range-slider {
	margin-top: 20px;
}

.price-range-wrap .range-slider .price-input {
	position: relative;
}

.price-range-wrap .range-slider .price-input:after {
	position: absolute;
	left: 38px;
	top: 13px;
	height: 1px;
	width: 5px;
	background: #dd2222;
	content: "";
}

.price-range-wrap .range-slider .price-input input {
	font-size: 16px;
	color: #dd2222;
	font-weight: 700;
	max-width: 20%;
	border: none;
	display: inline-block;
}

.price-range-wrap .price-range {
	border-radius: 0;
}

.price-range-wrap .price-range.ui-widget-content {
	border: none;
	background: #ebebeb;
	height: 5px;
}

.price-range-wrap .price-range.ui-widget-content .ui-slider-handle {
	height: 13px;
	width: 13px;
	border-radius: 50%;
	background: #ffffff;
	border: none;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
	outline: none;
	cursor: pointer;
}

.price-range-wrap .price-range .ui-slider-range {
	background: #dd2222;
	border-radius: 0;
}

.price-range-wrap .price-range .ui-slider-range.ui-corner-all.ui-widget-header:last-child {
	background: #dd2222;
}

.sidebar__item__color {
	float: left;
	width: 40%;
}

.sidebar__item__color.sidebar__item__color--white label:after {
	border: 2px solid #333333;
	background: transparent;
}

.sidebar__item__color.sidebar__item__color--gray label:after {
	background: #E9A625;
}

.sidebar__item__color.sidebar__item__color--red label:after {
	background: #D62D2D;
}

.sidebar__item__color.sidebar__item__color--black label:after {
	background: #252525;
}

.sidebar__item__color.sidebar__item__color--blue label:after {
	background: #249BC8;
}

.sidebar__item__color.sidebar__item__color--green label:after {
	background: #3CC032;
}

.sidebar__item__color label {
	font-size: 16px;
	color: #333333;
	position: relative;
	padding-left: 32px;
	cursor: pointer;
}

.sidebar__item__color label input {
	position: absolute;
	visibility: hidden;
}

.sidebar__item__color label:after {
	position: absolute;
	left: 0;
	top: 5px;
	height: 14px;
	width: 14px;
	background: #222;
	content: "";
	border-radius: 50%;
}

.sidebar__item__size {
	display: inline-block;
	margin-right: 16px;
	margin-bottom: 10px;
}

.sidebar__item__size label {
	font-size: 12px;
	color: #6f6f6f;
	display: inline-block;
	padding: 8px 25px 6px;
	background: #f5f5f5;
	cursor: pointer;
	margin-bottom: 0;
}

.sidebar__item__size label input {
	position: absolute;
	visibility: hidden;
}

/*---------------------
  Shop Grid
-----------------------*/

.product {
	padding-top: 80px;
	padding-bottom: 80px;
}

.product__discount {
	padding-bottom: 50px;
}

.product__discount__title {
	text-align: left;
	margin-bottom: 65px;
}

.product__discount__title h2 {
	display: inline-block;
}

.product__discount__title h2:after {
	margin: 0;
	width: 100%;
}

.product__discount__item:hover .product__discount__item__pic .product__item__pic__hover {
	bottom: 20px;
}

.product__discount__item__pic {
	height: 270px;
	position: relative;
	overflow: hidden;
}

.product__discount__item__pic .product__discount__percent {
	height: 45px;
	width: 45px;
	background: #dd2222;
	border-radius: 50%;
	font-size: 14px;
	color: #ffffff;
	line-height: 45px;
	text-align: center;
	position: absolute;
	left: 15px;
	top: 15px;
}

.product__item__pic__hover {
	position: absolute;
	left: 0;
	bottom: -50px;
	width: 100%;
	text-align: center;
	transition: all, 0.5s;
}

.product__item__pic__hover li {
	list-style: none;
	display: inline-block;
	margin-right: 6px;
}

.product__item__pic__hover li:last-child {
	margin-right: 0;
}

.product__item__pic__hover li:hover a {
	background: #ed1c23;
	border-color: #ed1c23;
}

.product__item__pic__hover li:hover a i {
	color: #ffffff;
	transform: rotate(360deg);
}

.product__item__pic__hover li a {
	font-size: 16px;
	color: #1c1c1c;
	height: 40px;
	width: 40px;
	line-height: 40px;
	text-align: center;
	border: 1px solid #ebebeb;
	background: #ffffff;
	display: block;
	border-radius: 50%;
	transition: all, 0.5s;
}

.product__item__pic__hover li a i {
	position: relative;
	transform: rotate(0);
	transition: all, 0.3s;
}

.product__discount__item__text {
	text-align: center;
	padding-top: 20px;
}

.product__discount__item__text span {
	font-size: 14px;
	color: #b2b2b2;
	display: block;
	margin-bottom: 4px;
}

.product__discount__item__text h5 {
	margin-bottom: 6px;
}

.product__discount__item__text h5 a {
	color: #1c1c1c;
}

.product__discount__item__text .product__item__price {
	font-size: 18px;
	color: #1c1c1c;
	font-weight: 700;
}

.product__discount__item__text .product__item__price span {
	display: inline-block;
	font-weight: 400;
	text-decoration: line-through;
	margin-left: 10px;
}

.product__discount__slider .col-lg-4 {
	max-width: 100%;
}

.product__discount__slider.owl-carousel .owl-dots {
	text-align: center;
	margin-top: 30px;
}

.product__discount__slider.owl-carousel .owl-dots button {
	height: 12px;
	width: 12px;
	border: 1px solid #b2b2b2;
	border-radius: 50%;
	margin-right: 12px;
}

.product__discount__slider.owl-carousel .owl-dots button.active {
	background: #707070;
	border-color: #6f6f6f;
}

.product__discount__slider.owl-carousel .owl-dots button:last-child {
	margin-right: 0;
}

.filter__item {
	padding-top: 45px;
	border-top: 1px solid #ebebeb;
	padding-bottom: 20px;
}

.filter__sort {
	margin-bottom: 15px;
}

.filter__sort span {
	font-size: 16px;
	color: #6f6f6f;
	display: inline-block;
}

.filter__sort .nice-select {
	background-color: #fff;
	border-radius: 0;
	border: none;
	display: inline-block;
	float: none;
	height: 0;
	line-height: 0;
	padding-left: 18px;
	padding-right: 30px;
	font-size: 16px;
	color: #1c1c1c;
	font-weight: 700;
	cursor: pointer;
}

.filter__sort .nice-select span {
	color: #1c1c1c;
}

.filter__sort .nice-select:after {
	border-bottom: 1.5px solid #1c1c1c;
	border-right: 1.5px solid #1c1c1c;
	height: 8px;
	margin-top: 0;
	right: 16px;
	width: 8px;
	top: -5px;
}

.filter__sort .nice-select.open .list {
	opacity: 1;
	pointer-events: auto;
	transform: scale(1) translateY(0);
}

.filter__sort .nice-select .list {
	border-radius: 0;
	margin-top: 0;
	top: 15px;
}

.filter__sort .nice-select .option {
	line-height: 30px;
	min-height: 30px;
}

.filter__found {
	text-align: center;
	margin-bottom: 15px;
}

.filter__found h6 {
	font-size: 16px;
	color: #b2b2b2;
}

.filter__found h6 span {
	color: #1c1c1c;
	font-weight: 700;
	margin-right: 5px;
}

.filter__option {
	text-align: right;
	margin-bottom: 15px;
}

.filter__option span {
	font-size: 24px;
	color: #b2b2b2;
	margin-right: 10px;
	cursor: pointer;
	transition: all, 0.3s;
}

.filter__option span:last-child {
	margin: 0;
}

.filter__option span:hover {
	color: #ed1c23;
}

.product__item {
	margin-bottom: 50px;
}

.product__item:hover .product__item__pic .product__item__pic__hover {
	bottom: 20px;
}

.product__item__pic {
	height: 270px;
	position: relative;
	overflow: hidden;
}

.product__item__pic__hover {
	position: absolute;
	left: 0;
	bottom: -50px;
	width: 100%;
	text-align: center;
	transition: all, 0.5s;
}

.product__item__pic__hover li {
	list-style: none;
	display: inline-block;
	margin-right: 6px;
}

.product__item__pic__hover li:last-child {
	margin-right: 0;
}

.product__item__pic__hover li:hover a {
	background: #ed1c23;
	border-color: #ed1c23;
}

.product__item__pic__hover li:hover a i {
	color: #ffffff;
	transform: rotate(360deg);
}

.product__item__pic__hover li a {
	font-size: 16px;
	color: #1c1c1c;
	height: 40px;
	width: 40px;
	line-height: 40px;
	text-align: center;
	border: 1px solid #ebebeb;
	background: #ffffff;
	display: block;
	border-radius: 50%;
	transition: all, 0.5s;
}

.product__item__pic__hover li a i {
	position: relative;
	transform: rotate(0);
	transition: all, 0.3s;
}

.product__item__text {
	text-align: center;
	padding-top: 15px;
}

.product__item__text h6 {
	margin-bottom: 10px;
}

.product__item__text h6 a {
	color: #252525;
}

.product__item__text h5 {
	color: #252525;
	font-weight: 700;
}

.product__pagination,
.blog__pagination {
	padding-top: 10px;
}

.product__pagination a,
.blog__pagination a {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 1px solid #b2b2b2;
	font-size: 14px;
	color: #b2b2b2;
	font-weight: 700;
	line-height: 28px;
	text-align: center;
	margin-right: 16px;
	transition: all, 0.3s;
}

.product__pagination a:hover,
.blog__pagination a:hover {
	background: #ed1c23;
	border-color: #ed1c23;
	color: #ffffff;
}

.product__pagination a:last-child,
.blog__pagination a:last-child {
	margin-right: 0;
}

/*---------------------
  Shop Details
-----------------------*/

.product-details {
	padding-top: 80px;
}

.product__details__pic__item {
	margin-bottom: 20px;
}

.product__details__pic__item img {
	min-width: 100%;
}

.product__details__pic__slider img {
	cursor: pointer;
}

.product__details__pic__slider.owl-carousel .owl-item img {
	width: auto;
}

.product__details__text h3 {
	color: #252525;
	font-weight: 700;
	margin-bottom: 16px;
}

.product__details__text .product__details__rating {
	font-size: 14px;
	margin-bottom: 12px;
}

.product__details__text .product__details__rating i {
	margin-right: -2px;
	color: #EDBB0E;
}

.product__details__text .product__details__rating span {
	color: #dd2222;
	margin-left: 4px;
}

.product__details__text .product__details__price {
	font-size: 30px;
	color: #dd2222;
	font-weight: 600;
	margin-bottom: 15px;
}

.product__details__text p {
	margin-bottom: 45px;
}

.product__details__text .primary-btn {
	padding: 16px 28px 14px;
	margin-right: 6px;
	margin-bottom: 5px;
}

.product__details__text .heart-icon {
	display: inline-block;
	font-size: 16px;
	color: #6f6f6f;
	padding: 13px 16px 13px;
	background: #f5f5f5;
}

.product__details__text ul {
	border-top: 1px solid #ebebeb;
	padding-top: 40px;
	margin-top: 50px;
}

.product__details__text ul li {
	font-size: 16px;
	color: #1c1c1c;
	list-style: none;
	line-height: 36px;
}

.product__details__text ul li b {
	font-weight: 700;
	width: 170px;
	display: inline-block;
}

.product__details__text ul li span samp {
	color: #dd2222;
}

.product__details__text ul li .share {
	display: inline-block;
}

.product__details__text ul li .share a {
	display: inline-block;
	font-size: 15px;
	color: #1c1c1c;
	margin-right: 25px;
}

.product__details__text ul li .share a:last-child {
	margin-right: 0;
}

.product__details__quantity {
	display: inline-block;
	margin-right: 6px;
}

.pro-qty {
	width: 140px;
	height: 50px;
	display: inline-block;
	position: relative;
	text-align: center;
	background: #f5f5f5;
	margin-bottom: 5px;
}

.pro-qty input {
	height: 100%;
	width: 100%;
	font-size: 16px;
	color: #6f6f6f;
	width: 50px;
	border: none;
	background: #f5f5f5;
	text-align: center;
}

.pro-qty .qtybtn {
	width: 35px;
	font-size: 16px;
	color: #6f6f6f;
	cursor: pointer;
	display: inline-block;
}

.product__details__tab {
	padding-top: 85px;
}

.product__details__tab .nav-tabs {
	border-bottom: none;
	justify-content: center;
	position: relative;
}

.product__details__tab .nav-tabs:before {
	position: absolute;
	left: 0;
	top: 12px;
	height: 1px;
	width: 370px;
	background: #ebebeb;
	content: "";
}

.product__details__tab .nav-tabs:after {
	position: absolute;
	right: 0;
	top: 12px;
	height: 1px;
	width: 370px;
	background: #ebebeb;
	content: "";
}

.product__details__tab .nav-tabs li {
	margin-bottom: 0;
	margin-right: 65px;
}

.product__details__tab .nav-tabs li:last-child {
	margin-right: 0;
}

.product__details__tab .nav-tabs li a {
	font-size: 16px;
	color: #999999;
	font-weight: 700;
	border: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	padding: 0;
}

.product__details__tab .product__details__tab__desc {
	padding-top: 44px;
}

.product__details__tab .product__details__tab__desc h6 {
	font-weight: 700;
	color: #333333;
	margin-bottom: 26px;
}

.product__details__tab .product__details__tab__desc p {
	color: #666666;
}

/*---------------------
  Shop Details
-----------------------*/

.related-product {
	padding-bottom: 30px;
}

.related__product__title {
	margin-bottom: 70px;
}

/*---------------------
  Shop Cart
-----------------------*/

.shoping-cart {
	padding-top: 80px;
	padding-bottom: 80px;
}

.shoping__cart__table {
	margin-bottom: 30px;
}

.shoping__cart__table table {
	width: 100%;
	text-align: center;
}

.shoping__cart__table table thead tr {
	border-bottom: 1px solid #ebebeb;
}

.shoping__cart__table table thead th {
	font-size: 20px;
	font-weight: 700;
	color: #1c1c1c;
	padding-bottom: 20px;
}

.shoping__cart__table table thead th.shoping__product {
	text-align: left;
}

.shoping__cart__table table tbody tr td {
	padding-top: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid #ebebeb;
}

.shoping__cart__table table tbody tr td.shoping__cart__item {
	width: 630px;
	text-align: left;
}

.shoping__cart__table table tbody tr td.shoping__cart__item img {
	display: inline-block;
	margin-right: 25px;
}

.shoping__cart__table table tbody tr td.shoping__cart__item h5 {
	color: #1c1c1c;
	display: inline-block;
}

.shoping__cart__table table tbody tr td.shoping__cart__price {
	font-size: 18px;
	color: #1c1c1c;
	font-weight: 700;
	width: 100px;
}

.shoping__cart__table table tbody tr td.shoping__cart__total {
	font-size: 18px;
	color: #1c1c1c;
	font-weight: 700;
	width: 110px;
}

.shoping__cart__table table tbody tr td.shoping__cart__item__close {
	text-align: right;
}

.shoping__cart__table table tbody tr td.shoping__cart__item__close span {
	font-size: 24px;
	color: #b2b2b2;
	cursor: pointer;
}

.shoping__cart__table table tbody tr td.shoping__cart__quantity {
	width: 225px;
}

.shoping__cart__table table tbody tr td.shoping__cart__quantity .pro-qty {
	width: 120px;
	height: 40px;
}

.shoping__cart__table table tbody tr td.shoping__cart__quantity .pro-qty input {
	color: #1c1c1c;
}

.shoping__cart__table table tbody tr td.shoping__cart__quantity .pro-qty input::placeholder {
	color: #1c1c1c;
}

.shoping__cart__table table tbody tr td.shoping__cart__quantity .pro-qty .qtybtn {
	width: 15px;
}

.primary-btn.cart-btn {
	color: #6f6f6f;
	padding: 14px 30px 12px;
	background: #f5f5f5;
}

.primary-btn.cart-btn span {
	font-size: 14px;
}

.primary-btn.cart-btn.cart-btn-right {
	float: right;
}

.shoping__discount {
	margin-top: 45px;
}

.shoping__discount h5 {
	font-size: 20px;
	color: #1c1c1c;
	font-weight: 700;
	margin-bottom: 25px;
}

.shoping__discount form input {
	width: 255px;
	height: 46px;
	border: 1px solid #cccccc;
	font-size: 16px;
	color: #b2b2b2;
	text-align: center;
	display: inline-block;
	margin-right: 15px;
}

.shoping__discount form input::placeholder {
	color: #b2b2b2;
}

.shoping__discount form button {
	padding: 15px 30px 11px;
	font-size: 12px;
	letter-spacing: 4px;
	background: #6f6f6f;
}

.shoping__checkout {
	background: #f5f5f5;
	padding: 30px;
	padding-top: 20px;
	margin-top: 50px;
}

.shoping__checkout h5 {
	color: #1c1c1c;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 28px;
}

.shoping__checkout ul {
	margin-bottom: 28px;
}

.shoping__checkout ul li {
	font-size: 16px;
	color: #1c1c1c;
	font-weight: 700;
	list-style: none;
	overflow: hidden;
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 13px;
	margin-bottom: 18px;
}

.shoping__checkout ul li:last-child {
	padding-bottom: 0;
	border-bottom: none;
	margin-bottom: 0;
}

.shoping__checkout ul li span {
	font-size: 18px;
	color: #dd2222;
	float: right;
}

.shoping__checkout .primary-btn {
	display: block;
	text-align: center;
}

/*---------------------
  Checkout
-----------------------*/

.checkout {
	padding-top: 80px;
	padding-bottom: 60px;
}

.checkout h6 {
	color: #999999;
	text-align: center;
	background: #f5f5f5;
	border-top: 1px solid #6AB963;
	padding: 12px 0 12px;
	margin-bottom: 75px;
}

.checkout h6 span {
	font-size: 16px;
	color: #6AB963;
	margin-right: 5px;
}

.checkout h6 a {
	text-decoration: underline;
	color: #999999;
}

.checkout__form h4 {
	color: #1c1c1c;
	font-weight: 700;
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 20px;
	margin-bottom: 25px;
}

.checkout__form p {
	column-rule: #b2b2b2;
}

.checkout__input {
	margin-bottom: 24px;
}

.checkout__input p {
	color: #1c1c1c;
	margin-bottom: 20px;
}

.checkout__input p span {
	color: #dd2222;
}

.checkout__input input {
	width: 100%;
	height: 46px;
	border: 1px solid #ebebeb;
	padding-left: 20px;
	font-size: 16px;
	color: #b2b2b2;
	border-radius: 4px;
}

.checkout__input input.checkout__input__add {
	margin-bottom: 20px;
}

.checkout__input input::placeholder {
	color: #b2b2b2;
}

.checkout__input__checkbox {
	margin-bottom: 10px;
}

.checkout__input__checkbox label {
	position: relative;
	font-size: 16px;
	color: #1c1c1c;
	padding-left: 40px;
	cursor: pointer;
}

.checkout__input__checkbox label input {
	position: absolute;
	visibility: hidden;
}

.checkout__input__checkbox label input:checked~.checkmark {
	background: #ed1c23;
	border-color: #ed1c23;
}

.checkout__input__checkbox label input:checked~.checkmark:after {
	opacity: 1;
}

.checkout__input__checkbox label .checkmark {
	position: absolute;
	left: 0;
	top: 4px;
	height: 16px;
	width: 14px;
	border: 1px solid #a6a6a6;
	content: "";
	border-radius: 4px;
}

.checkout__input__checkbox label .checkmark:after {
	position: absolute;
	left: 1px;
	top: 1px;
	width: 10px;
	height: 8px;
	border: solid white;
	border-width: 3px 3px 0px 0px;
	transform: rotate(127deg);
	content: "";
	opacity: 0;
}

.checkout__order {
	background: #f5f5f5;
	padding: 40px;
	padding-top: 30px;
}

.checkout__order h4 {
	color: #1c1c1c;
	font-weight: 700;
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.checkout__order .checkout__order__products {
	font-size: 18px;
	color: #1c1c1c;
	font-weight: 700;
	margin-bottom: 10px;
}

.checkout__order .checkout__order__products span {
	float: right;
}

.checkout__order ul {
	margin-bottom: 12px;
}

.checkout__order ul li {
	font-size: 16px;
	color: #6f6f6f;
	line-height: 40px;
	list-style: none;
}

.checkout__order ul li span {
	font-weight: 700;
	float: right;
}

.checkout__order .checkout__order__subtotal {
	font-size: 18px;
	color: #1c1c1c;
	font-weight: 700;
	border-bottom: 1px solid #e1e1e1;
	border-top: 1px solid #e1e1e1;
	padding-bottom: 15px;
	margin-bottom: 15px;
	padding-top: 15px;
}

.checkout__order .checkout__order__subtotal span {
	float: right;
}

.checkout__order .checkout__input__checkbox label {
	padding-left: 20px;
}

.checkout__order .checkout__order__total {
	font-size: 18px;
	color: #1c1c1c;
	font-weight: 700;
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 15px;
	margin-bottom: 25px;
}

.checkout__order .checkout__order__total span {
	float: right;
	color: #dd2222;
}

.checkout__order button {
	font-size: 18px;
	letter-spacing: 2px;
	width: 100%;
	margin-top: 10px;
}

/*---------------------
  Blog
-----------------------*/

.blog__item {
	margin-bottom: 60px;
}

.blog__item__pic img {
	min-width: 100%;
}

.blog__item__text {
	padding-top: 25px;
}

.blog__item__text ul {
	margin-bottom: 15px;
}

.blog__item__text ul li {
	font-size: 16px;
	color: #b2b2b2;
	list-style: none;
	display: inline-block;
	margin-right: 15px;
}

.blog__item__text ul li:last-child {
	margin-right: 0;
}

.blog__item__text h5 {
	margin-bottom: 12px;
}

.blog__item__text h5 a {
	font-size: 20px;
	color: #1c1c1c;
	font-weight: 700;
}

.blog__item__text p {
	margin-bottom: 25px;
}

.blog__item__text .blog__btn {
	display: inline-block;
	font-size: 14px;
	color: #1c1c1c;
	text-transform: uppercase;
	letter-spacing: 1px;
	border: 1px solid #b2b2b2;
	padding: 14px 20px 12px;
	border-radius: 25px;
}

.blog__item__text .blog__btn span {
	position: relative;
	top: 1px;
	margin-left: 5px;
}

.blog__pagination {
	padding-top: 5px;
	position: relative;
}

.blog__pagination:before {
	position: absolute;
	left: 0;
	top: -29px;
	height: 1px;
	width: 100%;
	background: #000000;
	opacity: 0.1;
	content: "";
}

/*---------------------
  Blog Sidebar
-----------------------*/

.blog__sidebar {
	padding-top: 50px;
}

.blog__sidebar__item {
	margin-bottom: 50px;
}

.blog__sidebar__item h4 {
	color: #1c1c1c;
	font-weight: 700;
	margin-bottom: 25px;
}

.blog__sidebar__item ul li {
	list-style: none;
}

.blog__sidebar__item ul li a {
	font-size: 16px;
	color: #666666;
	line-height: 48px;
	transition: all, 0.3s;
}

.blog__sidebar__item ul li a:hover {
	color: #ed1c23;
}

.blog__sidebar__search {
	margin-bottom: 50px;
}

.blog__sidebar__search form {
	position: relative;
}

.blog__sidebar__search form input {
	width: 100%;
	height: 46px;
	font-size: 16px;
	color: #6f6f6f;
	padding-left: 15px;
	border: 1px solid #e1e1e1;
	border-radius: 20px;
}

.blog__sidebar__search form input::placeholder {
	color: #6f6f6f;
}

.blog__sidebar__search form button {
	font-size: 16px;
	color: #6f6f6f;
	background: transparent;
	border: none;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	padding: 0px 18px;
}

.blog__sidebar__recent .blog__sidebar__recent__item {
	display: block;
}

.blog__sidebar__recent .blog__sidebar__recent__item:last-child {
	margin-bottom: 0;
}

.blog__sidebar__recent__item {
	overflow: hidden;
	margin-bottom: 20px;
}

.blog__sidebar__recent__item__pic {
	float: left;
	margin-right: 20px;
}

.blog__sidebar__recent__item__text {
	overflow: hidden;
}

.blog__sidebar__recent__item__text h6 {
	font-weight: 700;
	color: #333333;
	line-height: 20px;
	margin-bottom: 5px;
}

.blog__sidebar__recent__item__text span {
	font-size: 12px;
	color: #999999;
	text-transform: uppercase;
}

.blog__sidebar__item__tags a {
	font-size: 16px;
	color: #6f6f6f;
	background: #f5f5f5;
	display: inline-block;
	padding: 7px 26px 5px;
	margin-right: 6px;
	margin-bottom: 10px;
}

/*---------------------
  Blog Details Hero
-----------------------*/

.blog-details-hero {
	height: 350px;
	display: flex;
	align-items: center;
}

.blog__details__hero__text {
	text-align: center;
}

.blog__details__hero__text h2 {
	font-size: 46px;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 10px;
}

.blog__details__hero__text ul li {
	font-size: 16px;
	color: #ffffff;
	list-style: none;
	display: inline-block;
	margin-right: 45px;
	position: relative;
}

.blog__details__hero__text ul li:after {
	position: absolute;
	right: -26px;
	top: 0;
	content: "|";
}

.blog__details__hero__text ul li:last-child {
	margin-right: 0;
}

.blog__details__hero__text ul li:last-child:after {
	display: none;
}

/*---------------------
  Blog Details
-----------------------*/

.related-blog {
	padding-top: 70px;
	padding-bottom: 10px;
}

.related-blog-title {
	margin-bottom: 70px;
}

.blog-details {
	padding-bottom: 75px;
	border-bottom: 1px solid #e1e1e1;
}

.blog__details__text {
	margin-bottom: 45px;
}

.blog__details__text img {
	margin-bottom: 30px;
}

.blog__details__text p {
	font-size: 18px;
	line-height: 30px;
}

.blog__details__text h3 {
	color: #333333;
	font-weight: 700;
	line-height: 30px;
	margin-bottom: 30px;
}

.blog__details__author__pic {
	float: left;
	margin-right: 15px;
}

.blog__details__author__pic img {
	height: 92px;
	width: 92px;
	border-radius: 50%;
}

.blog__details__author__text {
	overflow: hidden;
	padding-top: 30px;
}

.blog__details__author__text h6 {
	color: #1c1c1c;
	font-weight: 700;
}

.blog__details__author__text span {
	font-size: 16px;
	color: #6f6f6f;
}

.blog__details__widget ul {
	margin-bottom: 5px;
}

.blog__details__widget ul li {
	font-size: 16px;
	color: #6f6f6f;
	list-style: none;
	line-height: 30px;
}

.blog__details__widget ul li span {
	color: #1c1c1c;
	font-weight: 700;
}

.blog__details__widget .blog__details__social a {
	display: inline-block;
	font-size: 20px;
	color: #6f6f6f;
	margin-right: 24px;
	transition: all, 0.3s;
}

.blog__details__widget .blog__details__social a:hover {
	color: #ed1c23;
}

.blog__details__widget .blog__details__social a:last-child {
	margin-right: 0;
}

/*---------------------
  Footer
-----------------------*/

.footer {
	background: #F3F6FA;
	padding-top: 70px;
	padding-bottom: 0;
}

.footer__about {
	margin-bottom: 30px;
}

.footer__about ul li {
	font-size: 16px;
	color: #1c1c1c;
	line-height: 36px;
	list-style: none;
}

.footer__about__logo {
	margin-bottom: 15px;
}

.footer__about__logo a {
	display: inline-block;
}

.footer__widget {
	margin-bottom: 30px;
	overflow: hidden;
}

.footer__widget h6 {
	color: #1c1c1c;
	font-weight: 700;
	margin-bottom: 10px;
}

.footer__widget ul {
	width: 50%;
	float: left;
}

.footer__widget ul li {
	list-style: none;
}

.footer__widget ul li a {
	color: #1c1c1c;
	font-size: 14px;
	line-height: 32px;
}

.footer__widget p {
	font-size: 14px;
	color: #1c1c1c;
	margin-bottom: 30px;
}

.footer__widget form {
	position: relative;
	margin-bottom: 30px;
}

.footer__widget form input {
	width: 100%;
	font-size: 16px;
	padding-left: 20px;
	color: #1c1c1c;
	height: 46px;
	border: 1px solid #ededed;
}

.footer__widget form input::placeholder {
	color: #1c1c1c;
}

.footer__widget form button {
	position: absolute;
	right: 0;
	top: 0;
	padding: 0 26px;
	height: 100%;
}

.footer__widget .footer__widget__social a {
	display: inline-block;
	height: 41px;
	width: 41px;
	font-size: 16px;
	color: #404040;
	border: 1px solid #ededed;
	border-radius: 50%;
	line-height: 38px;
	text-align: center;
	background: #ffffff;
	transition: all, 0.3s;
	margin-right: 10px;
}

.footer__widget .footer__widget__social a:last-child {
	margin-right: 0;
}

.footer__widget .footer__widget__social a:hover {
	background: #ed1c23;
	color: #ffffff;
	border-color: #ffffff;
}

.footer__copyright {
	border-top: 1px solid #ebebeb;
	padding: 15px 0;
	overflow: hidden;
	margin-top: 20px;
}

.footer__copyright__text {
	font-size: 14px;
	color: #1c1c1c;
	float: left;
	line-height: 25px;
}

.footer__copyright__payment {
	float: right;
}

/*---------------------
  Contact
-----------------------*/

.contact {
	padding-top: 80px;
	padding-bottom: 50px;
}

.contact__widget {
	margin-bottom: 30px;
}

.contact__widget span {
	font-size: 36px;
	color: #ed1c23;
}

.contact__widget h4 {
	color: #1c1c1c;
	font-weight: 700;
	margin-bottom: 6px;
	margin-top: 18px;
}

.contact__widget p {
	color: #666666;
	margin-bottom: 0;
}

/*---------------------
  Map
-----------------------*/

.map {
	height: 500px;
	position: relative;
}

.map iframe {
	width: 100%;
}

.map .map-inside {
	position: absolute;
	left: 50%;
	top: 160px;
	transform: translateX(-175px);
}

.map .map-inside i {
	font-size: 48px;
	color: #ed1c23;
	position: absolute;
	bottom: -75px;
	left: 50%;
	transform: translateX(-18px);
}

.map .map-inside .inside-widget {
	width: 350px;
	background: #ffffff;
	text-align: center;
	padding: 23px 0;
	position: relative;
	z-index: 1;
	box-shadow: 0 0 20px 5px rgba(12, 7, 26, 0.15);
}

.map .map-inside .inside-widget:after {
	position: absolute;
	left: 50%;
	bottom: -30px;
	transform: translateX(-6px);
	border: 12px solid transparent;
	border-top: 30px solid #ffffff;
	content: "";
	z-index: -1;
}

.map .map-inside .inside-widget h4 {
	font-size: 22px;
	font-weight: 700;
	color: #1c1c1c;
	margin-bottom: 4px;
}

.map .map-inside .inside-widget ul li {
	list-style: none;
	font-size: 16px;
	color: #666666;
	line-height: 26px;
}

/*---------------------
  Contact Form
-----------------------*/

.contact__form__title {
	margin-bottom: 50px;
	text-align: center;
}

.contact__form__title h2 {
	color: #1c1c1c;
	font-weight: 700;
}

.contact-form {
	padding-top: 80px;
	padding-bottom: 80px;
}

.contact-form form input {
	width: 100%;
	height: 50px;
	font-size: 16px;
	color: #6f6f6f;
	padding-left: 20px;
	margin-bottom: 30px;
	border: 1px solid #ebebeb;
	border-radius: 4px;
}

.contact-form form input::placeholder {
	color: #6f6f6f;
}

.contact-form form textarea {
	width: 100%;
	height: 150px;
	font-size: 16px;
	color: #6f6f6f;
	padding-left: 20px;
	margin-bottom: 24px;
	border: 1px solid #ebebeb;
	border-radius: 4px;
	padding-top: 12px;
	resize: none;
}

.contact-form form textarea::placeholder {
	color: #6f6f6f;
}

.contact-form form button {
	font-size: 18px;
	letter-spacing: 2px;
}

/*--------------------------------- Responsive Media Quaries -----------------------------*/

@media only screen and (min-width: 1200px) {
	.container {
		max-width: 1170px;
	}
}

/* Medium Device = 1200px */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.header__menu ul li {
		margin-right: 45px;
	}
	.hero__search__form {
		width: 490px;
	}
	.hero__categories__all {
		padding: 10px 25px 10px 20px;
	}
	.hero__categories ul {
		padding-left: 20px;
	}
	.latest-product__slider.owl-carousel .owl-nav {
		right: 0;
	}
	.product__details__tab .nav-tabs:before {
		width: 265px;
	}
	.product__details__tab .nav-tabs:after {
		width: 265px;
	}
	.shoping__discount form input {
		width: 240px;
	}
}

/* Tablet Device = 768px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.hero__categories {
		margin-bottom: 30px;
	}
	.hero__search__form {
		width: 485px;
	}
	.categories__slider.owl-carousel .owl-nav button {
		left: -20px;
	}
	.categories__slider.owl-carousel .owl-nav button.owl-next {
		right: -20px;
	}
	.filter__sort .nice-select {
		padding-left: 5px;
		padding-right: 28px;
	}
	.product__details__quantity {
		margin-bottom: 10px;
	}
	.product__details__text .primary-btn {
		margin-bottom: 10px;
	}
	.product__details__tab .nav-tabs:before {
		width: 150px;
	}
	.product__details__tab .nav-tabs:after {
		width: 150px;
	}
	.blog__details__author {
		overflow: hidden;
		margin-bottom: 25px;
	}
	.humberger__open {
		display: block;
		font-size: 22px;
		color: #1c1c1c;
		height: 35px;
		width: 35px;
		line-height: 33px;
		text-align: center;
		border: 1px solid #1c1c1c;
		cursor: pointer;
		position: absolute;
		right: 15px;
		top: 22px;
	}
	.header .container {
		position: relative;
	}
	.humberger__menu__wrapper {
		width: 300px;
		background: #ffffff;
		position: fixed;
		left: -300px;
		top: 0;
		height: 100%;
		overflow-y: auto;
		z-index: 99;
		padding: 30px;
		padding-top: 50px;
		opacity: 0;
		display: block;
		transition: all, 0.6s;
	}
	.humberger__menu__wrapper.show__humberger__menu__wrapper {
		opacity: 1;
		left: 0;
	}
	.humberger__menu__logo {
		margin-bottom: 30px;
	}
	.humberger__menu__logo a {
		display: inline-block;
	}
	.humberger__menu__contact {
		padding: 10px 0 13px;
	}
	.humberger__menu__contact ul li {
		font-size: 14px;
		color: #1c1c1c;
		position: relative;
		line-height: 30px;
		list-style: none;
	}
	.humberger__menu__contact ul li i {
		color: #252525;
		margin-right: 5px;
	}
	.humberger__menu__cart ul {
		display: inline-block;
		margin-right: 25px;
	}
	.humberger__menu__cart ul li {
		list-style: none;
		display: inline-block;
		margin-right: 15px;
	}
	.humberger__menu__cart ul li:last-child {
		margin-right: 0;
	}
	.humberger__menu__cart ul li a {
		position: relative;
	}
	.humberger__menu__cart ul li a i {
		font-size: 18px;
		color: #1c1c1c;
	}
	.humberger__menu__cart ul li a span {
		height: 13px;
		width: 13px;
		background: #ed1c23;
		font-size: 10px;
		color: #ffffff;
		line-height: 13px;
		text-align: center;
		font-weight: 700;
		display: inline-block;
		border-radius: 50%;
		position: absolute;
		top: 0;
		right: -12px;
	}
	.humberger__menu__cart .header__cart__price {
		font-size: 14px;
		color: #6f6f6f;
		display: inline-block;
	}
	.humberger__menu__cart .header__cart__price span {
		color: #252525;
		font-weight: 700;
	}
	.humberger__menu__cart {
		margin-bottom: 25px;
	}
	.humberger__menu__widget {
		margin-bottom: 20px;
	}
	.humberger__menu__widget .header__top__right__language {
		margin-right: 20px;
	}
	.humberger__menu__nav {
		display: none;
	}
	.humberger__menu__wrapper .header__top__right__social {
		display: block;
		margin-right: 0;
		margin-bottom: 20px;
	}
	.humberger__menu__wrapper .slicknav_btn {
		display: none;
	}
	.humberger__menu__wrapper .slicknav_nav .slicknav_item a {
		border-bottom: none !important;
	}
	.humberger__menu__wrapper .slicknav_nav {
		display: block !important;
	}
	.humberger__menu__wrapper .slicknav_menu {
		background: transparent;
		padding: 0;
		margin-bottom: 30px;
	}
	.humberger__menu__wrapper .slicknav_nav ul {
		margin: 0;
	}
	.humberger__menu__wrapper .slicknav_nav a {
		color: #1c1c1c;
		font-size: 16px;
		font-weight: 600;
		margin: 0;
		border-bottom: 1px solid #e1e1e1;
	}
	.humberger__menu__wrapper .slicknav_nav a:hover {
		border-radius: 0;
		background: transparent;
		color: #ed1c23;
	}
	.humberger__menu__wrapper .slicknav_nav .slicknav_row,
	.humberger__menu__wrapper .slicknav_nav a {
		padding: 8px 0;
	}
	.humberger__menu__overlay {
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
		content: "";
		z-index: 98;
		visibility: hidden;
		transition: all, 0.6s;
	}
	.humberger__menu__overlay.active {
		visibility: visible;
	}
	.header__top {
		display: none;
	}
	.header__menu {
		display: none;
	}
	.header__cart {
		text-align: center;
		padding: 10px 0 24px;
	}
	.over_hid {
		overflow: hidden;
	}
}

/* Wide Mobile = 480px */

@media only screen and (max-width: 767px) {
	.hero__categories {
		margin-bottom: 30px;
	}
	.hero__search {
		margin-bottom: 30px;
	}
	.hero__search__form {
		width: 100%;
	}
	.hero__search__form form input {
		width: 100%;
	}
	.hero__search__form form .hero__search__categories {
		display: none;
	}
	.hero__search__phone {
		float: left;
		margin-top: 30px;
	}
	.categories__slider.owl-carousel .owl-nav {
		text-align: center;
		margin-top: 40px;
	}
	.categories__slider.owl-carousel .owl-nav button {
		position: relative;
		left: 0;
		top: 0;
		-webkit-transform: translateY(0);
	}
	.categories__slider.owl-carousel .owl-nav button.owl-next {
		right: -10px;
	}
	.footer__copyright {
		text-align: center;
	}
	.footer__copyright__text {
		float: none;
		margin-bottom: 25px;
	}
	.footer__copyright__payment {
		float: none;
	}
	.filter__item {
		text-align: center;
	}
	.filter__option {
		text-align: center;
	}
	.product__details__pic {
		margin-bottom: 40px;
	}
	.product__details__tab .nav-tabs:before {
		display: none;
	}
	.product__details__tab .nav-tabs:after {
		display: none;
	}
	.shoping__cart__table {
		overflow-y: auto;
	}
	.shoping__discount form input {
		margin-bottom: 15px;
	}
	.blog__details__author {
		overflow: hidden;
		margin-bottom: 25px;
	}
	.humberger__open {
		display: block;
		font-size: 22px;
		color: #1c1c1c;
		height: 35px;
		width: 35px;
		line-height: 33px;
		text-align: center;
		border: 1px solid #1c1c1c;
		cursor: pointer;
		position: absolute;
		right: 15px;
		top: 22px;
	}
	.header .container {
		position: relative;
	}
	.humberger__menu__wrapper {
		width: 300px;
		background: #ffffff;
		position: fixed;
		left: -300px;
		top: 0;
		height: 100%;
		overflow-y: auto;
		z-index: 99;
		padding: 30px;
		padding-top: 50px;
		opacity: 0;
		display: block;
		transition: all, 0.6s;
	}
	.humberger__menu__wrapper.show__humberger__menu__wrapper {
		opacity: 1;
		left: 0;
	}
	.humberger__menu__logo {
		margin-bottom: 30px;
	}
	.humberger__menu__logo a {
		display: inline-block;
	}
	.humberger__menu__contact {
		padding: 10px 0 13px;
	}
	.humberger__menu__contact ul li {
		font-size: 14px;
		color: #1c1c1c;
		position: relative;
		line-height: 30px;
		list-style: none;
	}
	.humberger__menu__contact ul li i {
		color: #252525;
		margin-right: 5px;
	}
	.humberger__menu__cart ul {
		display: inline-block;
		margin-right: 25px;
	}
	.humberger__menu__cart ul li {
		list-style: none;
		display: inline-block;
		margin-right: 15px;
	}
	.humberger__menu__cart ul li:last-child {
		margin-right: 0;
	}
	.humberger__menu__cart ul li a {
		position: relative;
	}
	.humberger__menu__cart ul li a i {
		font-size: 18px;
		color: #1c1c1c;
	}
	.humberger__menu__cart ul li a span {
		height: 13px;
		width: 13px;
		background: #ed1c23;
		font-size: 10px;
		color: #ffffff;
		line-height: 13px;
		text-align: center;
		font-weight: 700;
		display: inline-block;
		border-radius: 50%;
		position: absolute;
		top: 0;
		right: -12px;
	}
	.humberger__menu__cart .header__cart__price {
		font-size: 14px;
		color: #6f6f6f;
		display: inline-block;
	}
	.humberger__menu__cart .header__cart__price span {
		color: #252525;
		font-weight: 700;
	}
	.humberger__menu__cart {
		margin-bottom: 25px;
	}
	.humberger__menu__widget {
		margin-bottom: 20px;
	}
	.humberger__menu__widget .header__top__right__language {
		margin-right: 20px;
	}
	.humberger__menu__nav {
		display: none;
	}
	.humberger__menu__wrapper .header__top__right__social {
		display: block;
		margin-right: 0;
		margin-bottom: 20px;
	}
	.humberger__menu__wrapper .slicknav_btn {
		display: none;
	}
	.humberger__menu__wrapper .slicknav_nav .slicknav_item a {
		border-bottom: none !important;
	}
	.humberger__menu__wrapper .slicknav_nav {
		display: block !important;
	}
	.humberger__menu__wrapper .slicknav_menu {
		background: transparent;
		padding: 0;
		margin-bottom: 30px;
	}
	.humberger__menu__wrapper .slicknav_nav ul {
		margin: 0;
	}
	.humberger__menu__wrapper .slicknav_nav a {
		color: #1c1c1c;
		font-size: 16px;
		font-weight: 600;
		margin: 0;
		border-bottom: 1px solid #e1e1e1;
	}
	.humberger__menu__wrapper .slicknav_nav a:hover {
		border-radius: 0;
		background: transparent;
		color: #ed1c23;
	}
	.humberger__menu__wrapper .slicknav_nav .slicknav_row,
	.humberger__menu__wrapper .slicknav_nav a {
		padding: 8px 0;
	}
	.humberger__menu__overlay {
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
		content: "";
		z-index: 98;
		visibility: hidden;
		transition: all, 0.6s;
	}
	.humberger__menu__overlay.active {
		visibility: visible;
	}
	.header__top {
		display: none;
	}
	.header__menu {
		display: none;
	}
	.header__cart {
		text-align: center;
		padding: 10px 0 24px;
	}
	.over_hid {
		overflow: hidden;
	}
}

/* Small Device = 320px */

@media only screen and (max-width: 479px) {
	.hero__search__form form .hero__search__categories {
		display: none;
	}
	.featured__controls ul li {
		margin-bottom: 10px;
	}
	.product__details__text ul li b {
		width: 100px;
	}
	.product__details__tab .nav-tabs li {
		margin-right: 20px;
	}
	.shoping__cart__btns {
		text-align: center;
	}
	.primary-btn.cart-btn.cart-btn-right {
		float: none;
		margin-top: 10px;
	}
	.shoping__checkout .primary-btn {
		display: block;
		text-align: center;
		padding: 10px 15px 10px;
	}
	.map .map-inside {
		transform: translateX(-125px);
	}
	.map .map-inside .inside-widget {
		width: 250px;
	}
	.product__details__tab .nav-tabs li {
		margin-right: 15px;
	}
	.shoping__discount form input {
		width: 100%;
	}
	.checkout__order {
		padding: 20px;
	}
	.blog__details__hero__text h2 {
		font-size: 24px;
	}
}
a{
	text-decoration: none;
}
/*  */
.latest-product__text.dsd h4{
	font-weight: 700;
    color: white;
    margin-bottom: 22px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 25px;
    background-color: rgb(255 200 0);
}
.latest-product__text.dsd h4 a{
	padding-right: 10px;
    float: right;
    font-size: 17px;
    color: white;
}
.latest-product__text.dsd{
	/* border: 1px solid #163856; */
    border-radius: 5px;
}
.brands_0 img{
	height: 160px;
	width:100%
}
.ghjk .sale-title{
	left: 0px;
    position: absolute;
    top: 0;
	font-size: 12px;
    padding: 2px 15px 2px 15px;
    color: #fff;
	background-color: #ed1c23;
    -webkit-clip-path: unset;
            clip-path: unset;
    transform: rotate(-45deg);
    margin-top: 25px;

}
.ghjk-best .sale-title {
    left: 0px;
    position: absolute;
    top: 0;
    font-size: 12px;
    padding: 2px 15px 2px 15px;
    color: #fff;
    background-color: rgb(255 200 0);
    transform: rotate(-45deg);
    margin-top: 22px;
}
.arrow-co a{
	color: black;
    font-size: 20px;
    float: right;
    margin-right: 46px;
}
.testimonial_img{
	width:90px !important;
	height:90px;
	border-radius:50%;
	position: relative;
    z-index: 999;
}
.testi_content span img{
	width:51px !important;
	height:51px
}
.testi_content span{
	position: absolute;
    margin-left: -9px
}
.testi_man{
	display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.texti_img{
	display: flex;
    justify-content: center;
}
.testi_content{
	border: 1px solid #3f3f3f12;
    background: #3f3f3f1c;
    border-radius: 5px;
}

.testi_content p{
	padding: 20px 30px 10px;
	text-align: center;
	color: white;
}
.owl-theme .owl-dots .owl-dot.active span{
	background: #ed1c23;
}
.mo_0{
	text-align: center;
    padding: 20px;
	background-color: goldenrod;
}
.ba_l_0{
	border: 1px solid #74dbda;
    background-color: #74dbda;
    color: white;
    border-radius: 5px;
}
.mo_0 h2{
	font-weight: 600;
	font-size: 50px;
	line-height: 31px;
	
}
.tran{
	font-size: 25px;
    font-weight: 700;
    color: #333333;
	text-align: center;
}
.walls{
	display: flex;
    justify-content: space-between;
}
.icon-wal{
	display: flex;
	font-size: "18px"
}
.P_J_w{
	line-height: 19px;
}
.P_J_w span{
	color: #3d459a;
    font-size: 17px;

}
.track_list ul{
	display: flex;
    grid-gap: 20px;
    gap: 20px;
}
.track_order_list{
	display: flex;
	justify-content:space-between;
	background-color: #89858526;
    padding: 5px 5px 4px;
    border-bottom: 1px solid #3f3f3f3f;
}
.track_order_list ul{
	display: flex;
    grid-gap: 51px;
    gap: 51px;
}
.track_0_8{
display: flex;
grid-gap:30px;
gap:30px;
padding: 21px;
}
.image_00{
	width:18%;
}
.con_passage{
	width:90%;
	display: flex;
	justify-content: space-between;
}
.latest-product.spad.track{
	padding: 35px 180px;
}
.track_12{
	border: 1px solid #3f3f3f3f;
	margin-bottom: 10px;
    border-radius: 7px;
}
.track_order_list ul li{
	font-size: 14px;
}
.orderlist_00{
	cursor: pointer;
}
.active-tab-0{
	border-bottom: 2px solid red;
	color:red
}
.a_0_9{
	width:80%;
	color: #0d6efd;
}

@media screen and (max-width: 600px) {
	.hero__item {
		height: unset;
	}
	.carousel img {
		height: 201px !important;
	}
	.ban_o img{
		height: 141px !important;
	}
	.banner-hover img{
		height: 155px !important;margin-bottom:5px ;
	}
	.featured__item__text h6 a {
		font-size: 14px;
		font-weight: 700;
	}
	.brands_0 img {
		height: unset;
	}
	.social {
		display: block;
	}
	.search-bar.site-header__search {
		margin-top: 20px;
	}
	.search-btn{
		position: absolute;
		right: 0px;
	}
	.pro-quick{
		display: block;
	}
	.quick-view{
		width: 100% !important;
	}
	.singal_hero{
		margin-top: 30px;
	}
	.product__images {
		grid-gap: 5px;
		gap: 5px;
		display: flex;
	}
	.img-fluid.img1{
		height:175px !important
	}
	.hero {
		padding-bottom: 7px;
		margin-top: 61px;
	}
	.user.reg-0 {

		flex-direction: column;
		margin-top: 51px;
		padding: 20px;
	}
	
}
.filter_he{
    padding-top: 30px;
    padding-bottom: 30px;
}
.pages-filter{
    display: flex;
    justify-content: space-between;
}
.multiple-filters.scrollbar{
    list-style: none;
    padding-left: 0px;
}
.size-hero{
    display: flex;
    justify-content: space-between;
    padding-bottom: 7px;
    cursor: pointer;
    color:#6c757d
}
.fill_h {
    color: #333333;
    font-size: 19px;
    font-weight: 600;
}
.filter_pro{
    font-size: 15px;
    color: #5f5f5f;
}
.filter-name label{
    padding-left: 6px;
    font-size: 15px;
}
.product-count {
    border-bottom: 1px solid #5f5f5f  
}
.product-count .css-187mznn-MuiSlider-root{
    
    color: #ed1c23 !important;
}
.css-exkjcx {
  color: #ed1c23 !important;
}
.select-sort{
    color: #6c757d;
    font-size: 15px;
    border: 1px solid black;
    padding:0px;
    border-radius: 5px;
}
.paginationBttns {
    width: 80%;
    height: 40px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
    list-style: none;
    margin-top: 30px;
  }
  
  .paginationBttns a {
    text-decoration: none;
    padding: 5px 18px;
    background: rgb(255 200 0);;
    color: #fff !important;
    display: flex;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 5px;
    border-radius: 50%;
    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 10%);
  }
  
  .paginationBttns a:hover {
    background: #df2020;
  }
  .filter_search{
    display: flex;
    justify-content: space-between;
  }
  .search-bar.site-header__search{
    position: relative;
    border: 1px solid #141212;
    height: 42px;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;

  }
  .input-text{
    border: none;
    padding: 4px 10px;
  }
  .search-btn{
    /* border: none; */
    height: 40px;
    padding: 5px;
    width: 40px;
    background: #fdfdfd;
    border: 1px solid #dbd8d8;
    border-radius: 50%
}
.wt-50 {
    height: 83px !important;
    width: 160px;
}

.product-title h2 {
    font-size: 18px;
    font-weight: 600;
}

.product-inventory h6 {
    margin-right: 15px;
}

.pro-price-label {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.price-box .new-price {
    font-size: 16px;
    font-weight: 600;
}

.price-box .old-price {
    font-size: 16px;
    text-decoration: line-through;
    padding-left: 5px;
}

.product-label {
    margin-left: 5px;
}

.product_details_info .product-description {
    margin-top: 10px;
    color: #9a9a9a;
}

.pro-detail-button-0 {
    display: flex;
    grid-gap: 35px;
    gap: 35px;
}

.wish-09 {
    font-size: 14px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    background-color: #3d459a;
    justify-content: center;
    border-radius: 50px;
    color: white;
    cursor: pointer;
    margin-right: 5px;
    height: 40px;
    border: 1px solid #3d459a;
}

.product-sku {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #ededed;
    display: flex;
    align-items: center;
}

.product-sku span {
    margin-left: 15px;
    line-height: 1;
}

.tab-0-active {
    border: 1px solid #ed1c23;
    cursor: pointer;

}
.img__item:hover{
    border: 1px solid #ed1c23;
    cursor: pointer;
}

.presentation {
    justify-content: center;
    display: flex;
    list-style: none;
    grid-column-gap: 80px;
    column-gap: 80px;
}

.des-0__0 {
    padding: 8px 15px;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    border-radius: 50px;
    text-transform: uppercase;
    color: #949494;
}

.uni-details-hero {
    padding: 40px 20px;
}

.pre-active {
    border: 1px solid black;
    color: black;
    border-radius: 50px;
    cursor: pointer;
}

.des-0__0:hover {
    border: 1px solid black;
    color: black;
    cursor: pointer;
}

.spr-form-input-text {
    border: 1px solid #e5e5e5;
    padding: 8px 10px;
    border-radius: 3px;
    width: 100%;
    outline: 0;
}

.spr-header-title {
    font-size: 21px;
    font-weight: 600;
}

.write_re {
    font-size: 16px;
    color: #3d459a;
    cursor: pointer;
    text-decoration: underline;
}

.write_re__090 {
    font-size: 16px;
    color: #3d459a;
    cursor: pointer;
    border: none;
    background-color: unset !important;
    text-decoration: underline;

}
.re-pro-0-9 h4{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
    position: relative;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    text-align: center;
}
.deliver-1 h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #0F1111!important;
    font-family: 'Roboto';

}
.check{
    cursor: pointer;
}
.jkh{
    text-align: end;
    text-align: end;
    color: #007185;
    font-size: 14px;
}
.details-2 p{
    color: #333;
    font-size: 14px;
    font-weight: 600;
}
.details-2  p{
    color:#333;
    font-size: 14px;
    font-weight:600
}
.jh_0{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c9bcbc;
    margin-bottom: 13px
}
.pn-0{
    padding: 20px;
    margin: 10px 20px;
    border: 1px #D5D9D9 solid;
    border-radius: 8px 8px 0 0;
}
h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #0F1111!important;
    font-family: 'Roboto';

}
.bdm{
    display: flex;
    grid-gap: 10px;
    gap: 10px;

}
.bdm p{
    font-size: 14px;
    color: #333;
    font-weight: 600;
}
.address-ui-widgets{
    background-color: #fff;
    height: 31px;
    padding: 3px 7px;
    line-height: normal;
    border: 1px solid #888C8C;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(15,17,17,.15) inset;
    outline: 0;
    width: 100%;
}
input[type=text]:focus{
    background-color: #F7FEFF;
    border-color: #007185;
    box-shadow: 0 0 0 3px #C8F3FA, 0 1px 2px rgba(15,17,17,.15) inset;
}
input[type=tel]:focus{
    background-color: #F7FEFF;
    border-color: #007185;
    box-shadow: 0 0 0 3px #C8F3FA, 0 1px 2px rgba(15,17,17,.15) inset;
}
.modal-header{
    background: #1e224d7a;
}
.modal-title h3{
    color: #fff !important;
}
.joker{
    display: flex;
    grid-gap: 21px;
}
.btn-close{
    color:white !important
}
.icon-add{
    font-size: 24px;
    color: #333333b5;
}
.add_new_ad span{
    font-size: 14px;
    font-weight: 600;
}
.modal-title h5{
    color:white !important
}
.check{
    padding: 30px 0px 10px;
}
.check_0 h5{
    font-size: 23px;
    font-weight: 600;
}
.add_new_ad button {
border: 1px solid #e1e10b;
background: #e1e10b;
color: white;
padding: 6px 25px;
border-radius: 2px;
}
.jh_0{
    font-size: 19px;
    font-weight: 600;
}
.market_0{
    display: flex;
    grid-gap: 10px;
    gap: 10px
}
.css-dev-only-do-not-override-ph9edi span{
    font-size: 13px;
    font-weight: 600;
}
/* .order-summary-unidenfitied-style{
    display: flex;
    gap: 40px;
} */
.a-text-left{
    font-size: 15px;
    font-weight: 600;
}
.summer{
    border: 1px solid #c7bbbb;
    padding: 5px 35px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.cart-page .item-wrap ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
}
.item-info {
    display: flex;
}
.cart-wrap{
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
}
.item-info .item-title{
    margin-left: 12px;
    line-height: 16px;
}
.item-title p{
    margin-left: 12px;
    line-height: 16px;
}
.item-title .fresh_hero{
    font-size: 16px;
    font-weight: 600;
    color: #333333ed;
}
.cart_hero .cart-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-top: 1px solid #eeeeee;
}
.cart-title h2 {
    font-size: 16px;
    font-weight: 600;
}
.cart{
    padding-top:70px
}
.cart-total-wrap {
    padding: 15px;
    background-color: #f7f7f7;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.cart-sub-total{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.country_hero{
    padding: 8px 10px;
    border: 1px solid #eeeeee;
    background-color: #fff;
    font-size: 13px;
    font-weight: 400;
    width:100%;
    outline: 0px;
}
label {
    font-size: 13px;
    margin-bottom: 5px;
}
.culculate-shipping {
    font-size: 14px;
    font-weight: 600;
}
.detail_hero{
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    position: relative;
    border: 2px solid #ed1c23;
    max-width: 100%;
    border-radius: 3px;
    width: 100%;
    height:48px
}
.user.reg-0{
    grid-gap:60px;
    gap:60px;
    display:flex;
    justify-content: center;
}
.detail_hero .regis-pro{
    border:none;
    padding: 10px 15px 10px 15px;
    outline-width: 0px;
    border-radius: 0px;
}
.detail_hero label{
    background: #ed1c23;
    height: 44px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: 700;  
}
.detail_hero .ant-input-affix-wrapper{
    border: none;
   font-size: 15px;
   margin-right: 5px;
}
.form-action-button button{
    padding: 13px 25px;
    font-weight: 600;
    color: #fff;
    width: 100%;
    border-radius: 3px;
    border: 2px solid #ed1c23;
    background: #ed1c23;
    font-size: 16px;
}
.user-re{
  border: 2px solid #ed1c23;;
    padding: 30px;
   
}
.form-action-button-0 button{
    padding: 13px 30px;
    font-weight: 600;
    color:#333333;
    width: 100%;
    border-radius: 3px;
    border: 2px solid #ed1c23;
    font-size: 16px;
    background: white;
}
.sec-bg {
    padding: 20px;
    border-radius: 144px;
    padding-bottom: 40px;
    text-align: center;
    margin-bottom: 20px;
}
.sec-bg h3 {
    font-size: 22px;
    margin-top: 10px;
    font-weight: 600;
}
.sec-bg p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #242424;
    margin-bottom: 15px;
}
.lazyload-0{
    width:100%
}
.sec-bg a{
    color: black;
    text-decoration: none;
    border: 1px solid black;
    padding: 8px;
    border-radius: 5px;
}
.form-control {
    border: 0px!important;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    outline-width: 0px;
}

.partner-wrapper {
    max-width: 1000px !important;
    padding:21px;
}
.deliver-1 h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #0F1111!important;
    font-family: 'Roboto';

}
.check{
    cursor: pointer;
}
.jkh{
    text-align: end;
    text-align: end;
    color: #007185;
    font-size: 14px;
}
.details-2 p{
    color: #333;
    font-size: 14px;
    font-weight: 600;
}
.details-2  p{
    color:#333;
    font-size: 14px;
    font-weight:600
}
.jh_0{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c9bcbc;
    margin-bottom: 13px
}
.pn-0{
    padding: 20px;
    margin: 10px 20px;
    border: 1px #D5D9D9 solid;
    border-radius: 8px 8px 0 0;
}
h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #0F1111!important;
    font-family: 'Roboto';

}
.bdm{
    display: flex;
    grid-gap: 10px;
    gap: 10px;

}
.bdm p{
    font-size: 14px;
    color: #333;
    font-weight: 600;
}
.address-ui-widgets{
    background-color: #fff;
    height: 31px;
    padding: 3px 7px;
    line-height: normal;
    border: 1px solid #888C8C;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(15,17,17,.15) inset;
    outline: 0;
    width: 100%;
}
input[type=text]:focus{
    background-color: #F7FEFF;
    border-color: #007185;
    box-shadow: 0 0 0 3px #C8F3FA, 0 1px 2px rgba(15,17,17,.15) inset;
}
input[type=tel]:focus{
    background-color: #F7FEFF;
    border-color: #007185;
    box-shadow: 0 0 0 3px #C8F3FA, 0 1px 2px rgba(15,17,17,.15) inset;
}
.modal-header{
    background: rgb(254,200,0);
}
.modal-title h3{
    color: #fff !important;
}
.joker{
    display: flex;
    grid-gap: 21px;
}
.btn-close{
    color:white !important
}
.icon-add{
    font-size: 24px;
    color: #333333b5;
}
.add_new_ad span{
    font-size: 14px;
    font-weight: 600;
}
.modal-title h5{
    color:white !important
}
.check{
    padding: 30px 0px 10px;
}
.check_0 h5{
    font-size: 23px;
    font-weight: 600;
}
.add_new_ad button {
border: 1px solid #e1e10b;
background: #e1e10b;
color: white;
padding: 6px 25px;
border-radius: 2px;
}
.jh_0{
    font-size: 19px;
    font-weight: 600;
}
.market_0{
    display: flex;
    grid-gap: 10px;
    gap: 10px
}
.css-dev-only-do-not-override-ph9edi span{
    font-size: 13px;
    font-weight: 600;
}
/* .order-summary-unidenfitied-style{
    display: flex;
    gap: 40px;
} */
.a-text-left{
    font-size: 15px;
    font-weight: 600;
}
.summer{
    border: 1px solid #c7bbbb;
    padding: 5px 35px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.section-ptb {
    padding-top: 80px;
    padding-bottom: 80px;
}
.page-not-found .search-error-wrapper {
    text-align: center;
}
.page-not-found .search-error-wrapper h1 {
    font-size: 30px;
    font-weight: 600;
}
.page-not-found .search-error-wrapper h1 span:nth-child(odd){
    color: #9dcd45;
}
.page-not-found .search-error-wrapper h2 span:nth-child(odd){
    color: #9dcd45;
}
.page-not-found .search-error-wrapper h2 {
    font-size: 50px;
    font-weight: 600;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  overflow-x: hidden;
  width: 100%;
  padding-right: 0px !important;
}
html{
  overflow-x: hidden;
  width: 100%;
}

.header-top {
  /* background-color: #efefef; */
  padding: 10px 0px;
  color: white;
}
.slider img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.category-carousel img {
  width: 100%;
  height: 241px !important;
  object-fit: cover;
  border-radius: 50%;
}
.login {
  padding: 100px 0px;
  background-image: url("");
}
.login a {
  color: white;
}
.login-modal {
  background-color: #b382823b !important;
}
.top-header {
  padding: 1px 20px;
}
/* .header {
  background-color: #d1cece !important;
} */

.custom-sidebar {
  height: 550px !important;
  width: 189px !important;
  min-width: 211px !important;
}
.login-form {
  width: 40%;
  margin: 50px auto;
  padding: 50px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
}
/* .custom-nav {
  background-color: #313d87 !important;
} */
.category-carousel h6 {
  text-align: center;
  padding: 10px;
}
.header-signin {
  color: #ed2025;
  font-weight: 500;
  padding: 28px 22px 17px 6px;
  cursor: pointer;
}
.topdeals-carousel img {
  height: 135px;
  object-fit: cover;
}
.topdeals-carousel .items {
  border: 1px solid #dad4d4;
  padding: 10px 8px;
  border-radius: 11px;
}
.cart-btn {
  background-color: #c54f78 !important;
  color: white !important;
}
.header-top .form-control {
  border: 2px solid #ffffff !important;
  border-radius: 12px !important;
  box-shadow: 0px 0px 4px 1px #bb2d5f;
}
.header-top .form-group {
  padding: 20px 0px 0px;
}
.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}
.footer {
  background-color: rgb(254 200 0);
  padding: 48px 14px 0px;
}
.bottom-footer {
  border-top: 1px solid #cecbcb;
}
ul {
  list-style: none;
  padding-left: 0px !important;
}
.footer h6 {
  font-size: 18px;
  font-weight: 700;
}
.form-group {
  margin-bottom: 15px;
}
.form-group {
  font-weight: 600;
}
.custom-nav .nav-link {
  padding: 0px;
}
.custom-offer .col {
  margin: 20px 0px 20px;
}
.productdetail-img {
  height: 350px !important;
  width: 100% !important;
}
.essential-carousel img {
  height: 242px !important;
}

.custom-nav{
  background-image: linear-gradient(to right, #bf1884 , var(--bs-purple));
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1320px !important;
  }
  .category-container {
    max-width: 1226px !important;
    margin: auto;
  }
}
@media screen and (max-width: 767px) {
  .category-carousel img {
    height: 192px !important;
  }
}
.allcategory img {
  height: 230px;
  object-fit: cover;
}
.makeStyles-drawerHeader-8 {
  background: #aab2e3 !important;
}
.home-local {
  box-shadow: 0px 1px 5px 1px #bc3364;
  text-align: center;
}

.home-local .card-title {
  color: #313d87;
  font-weight: bold;
}
.r-img{
  border-radius: 149px !important;
}
.button{
  background-color: white !important;
  border:1px solid white !important;
}
.bg:active{
  background: red;
}

.my-modal .modal-content {
  background-color: none !important;
  width: 560px !important;
  left: -133px !important;
}
.selected{
  border: 1px solid red;
}
.head{

  font-weight: 500;
  padding: 7px 7px 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  margin-left: 60px;
}
.border img{
  width: 100% !important;

}

@media screen and (max-width: 767px) {
  .head{
    margin-left: 0px !important;
  }
  .my-modal .modal-content {
    /* background-color: none !important; */
    width: 0px !important;
    left: 0px !important;
  }
  .form-control{
    width:135px !important;
    margin-left: -14px !important;
  }
}
.content{
    position: fixed;
    background: red;
    right: -8px;
    bottom: 63px;
    border-radius: 24px;
    z-index: 1000;
}
.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  width: -webkit-max-content!important;
  width: max-content!important;
}
.goog-te-gadget .goog-te-combo {
  margin: 4px 0;
  padding: 9px;
  border: none;
  font-size: medium;
}
.goog-te-gadget {
  font-family: arial;
  font-size: 11px;
  color: #666;
  white-space: nowrap;
  display: grid;
}
.goog-te-banner-frame.skiptranslate {
  display: none;
}
.makeStyles-drawerHeader-8 {
  color: white;
  display: flex;
  padding: 0px 8px;

  min-height: 56px;
  align-items: center;
  justify-content: flex-start!important;
}
/* -----------------------------------------sam---------------------------------------------- */
.admin-bg{
/* background-color: #2e3da0ad; */
margin: 0em 10em;
}
/* .header{
  background-color: #2e3da0ad !important;
} */
.close{
  background-color: #2e3da0ad; 
}
.pro-sidebar > .pro-sidebar-inner {
  background: rgb(254 200 3);
}
.pro-item-content{
  color: white !important;
  font-size: 20px !important;
  font-weight: 700;
}
.pro-arrow-wrapper{
  color: white;
  font-size: 20px !important;
  font-weight: 400;
}
.end{
  display: flex;
  justify-content: end;
}
/* ============================================================== */
.i-con{
  font-size: 7rem;
  color: white;
  padding: 10px;
}
.red{
  background: #B9231F;
  padding: 20px;
  width: 10rem;
  margin: 2%;
}
.d-text{
  color: white;
  text-align: center;
}
.center{
  display: flex;
  justify-content: center;
}
.background{
  background: #E9F0F9;
  color: #E9F0F9;
  width: 100%;
  height: 100%;
}
.green{
 background: #31A221;
 padding: 20px;
 width: 10rem;
 margin: 2%;
}
.pink{
  background: #C7266C;
  padding: 20px;
  width: 10rem;
  margin: 2%;
}
.blue{
  background: #3AB2DB;
  padding: 20px;
  width: 10rem;
  margin: 2%;
}
.orange{
  background: #E79712;
  padding: 20px;
  width: 10rem;
  margin: 2%;
}
.darkb{
  background: #381095;
  padding: 20px;
  width: 10rem;
  margin: 2%;
}
.grid6{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
}
.wred{
  background: #B9231F;
  padding: 20px;
  width: 14rem;
  margin: 2%;
}
.wdarkb{
  background: #381095;
  padding: 20px;
  width: 14rem;
  margin: 2%;
}
.wgreen{
  background: #31A221;
  padding: 20px;
  width: 14rem;
  margin: 2%;
}
.worange{
  background: #E79712;
  padding: 20px;
  width: 14rem;
  margin: 2%;
}
.count{
  color: white;
  text-align: center;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  display: inline-block;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 4px 4px 0 !important;
  padding: 2.5px;
  font-weight: bold !important;
  vertical-align: middle;
  transition: transform 0.3s;
  transform: rotate(-45deg);
}
.ant-switch.jdsvljds.css-dev-only-do-not-override-mxhywb.ant-switch-checked{
  background: #dc3545 !important;
}
.delete-button{
  color: #b91f74;
  font-size: 30px;

}
.end{
  display: flex;
  justify-content: end;
}
.view-button{
  color: #313d87;
  font-size: 30px;
}

