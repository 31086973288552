.deliver-1 h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #0F1111!important;
    font-family: 'Roboto';

}
.check{
    cursor: pointer;
}
.jkh{
    text-align: end;
    text-align: end;
    color: #007185;
    font-size: 14px;
}
.details-2 p{
    color: #333;
    font-size: 14px;
    font-weight: 600;
}
.details-2  p{
    color:#333;
    font-size: 14px;
    font-weight:600
}
.jh_0{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c9bcbc;
    margin-bottom: 13px
}
.pn-0{
    padding: 20px;
    margin: 10px 20px;
    border: 1px #D5D9D9 solid;
    border-radius: 8px 8px 0 0;
}
h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #0F1111!important;
    font-family: 'Roboto';

}
.bdm{
    display: flex;
    gap: 10px;

}
.bdm p{
    font-size: 14px;
    color: #333;
    font-weight: 600;
}
.address-ui-widgets{
    background-color: #fff;
    height: 31px;
    padding: 3px 7px;
    line-height: normal;
    border: 1px solid #888C8C;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(15,17,17,.15) inset;
    outline: 0;
    width: 100%;
}
input[type=text]:focus{
    background-color: #F7FEFF;
    border-color: #007185;
    box-shadow: 0 0 0 3px #C8F3FA, 0 1px 2px rgba(15,17,17,.15) inset;
}
input[type=tel]:focus{
    background-color: #F7FEFF;
    border-color: #007185;
    box-shadow: 0 0 0 3px #C8F3FA, 0 1px 2px rgba(15,17,17,.15) inset;
}
.modal-header{
    background: rgb(254,200,0);
}
.modal-title h3{
    color: #fff !important;
}
.joker{
    display: flex;
    grid-gap: 21px;
}
.btn-close{
    color:white !important
}
.icon-add{
    font-size: 24px;
    color: #333333b5;
}
.add_new_ad span{
    font-size: 14px;
    font-weight: 600;
}
.modal-title h5{
    color:white !important
}
.check{
    padding: 30px 0px 10px;
}
.check_0 h5{
    font-size: 23px;
    font-weight: 600;
}
.add_new_ad button {
border: 1px solid #e1e10b;
background: #e1e10b;
color: white;
padding: 6px 25px;
border-radius: 2px;
}
.jh_0{
    font-size: 19px;
    font-weight: 600;
}
.market_0{
    display: flex;
    gap: 10px
}
.css-dev-only-do-not-override-ph9edi span{
    font-size: 13px;
    font-weight: 600;
}
/* .order-summary-unidenfitied-style{
    display: flex;
    gap: 40px;
} */
.a-text-left{
    font-size: 15px;
    font-weight: 600;
}
.summer{
    border: 1px solid #c7bbbb;
    padding: 5px 35px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
