.single-service li {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.single-service li span {
    font-size: 18px;
    width: calc(100% - 115px);
    color: white;
    font-weight: 600;
}

.single-service li a {
    position: relative;
    z-index: 0;
    margin-right: 15px;
    line-height: 0;
    font-size: 30px;
    color: #fff;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #ed1c23;
    background-color: #ed1c23;
}

.bottom_0 {
    border-bottom: 1px solid #ed1c23
}

.logo p {
    font-size: 17px;
    display: block;
    color: white;
    font-weight: 600;
}

.address-icon {
    display: flex;
    gap: 25px
}

.address-icon span i {
    font-size: 32px;
    color: #ed1c23;
}

.address-icon h6 {
    font-size: 20px;
    font-weight: 600;
    color: #ed1c23;
}

.address-icon p {
    font-size: 17px;
    display: block;
    color: white;
    font-weight: 600;
}

.address-icon .jgp {
    font-size: 17px;
    display: block;
    color: white;
    font-weight: 600;
}

.social {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sociale_icons ul {
    display: flex;
    list-style: none;
    margin-top: 10px;
    margin-bottom: 0px;
}
.social_0{
    margin-top: 20px;
    padding: 0px 21px;
}

.sociale_icons ul li {
    margin: 0px 12px;
    border: 1px solid #ed1c23;
    padding: 10px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(254 200 0);
}

.sociale_icons ul li i {
    color: white;
    font-size: 25px

}

.diff {
    background-color: #ed1c23;
}
.sociale_icons ul li:hover{
    border: 1px solid white;
    background-color: white;
    transform: translateY(-5px);
    transition: all .2s ease-in-out ;
}
.sociale_icons ul li i:hover {
   color:#ed1c23
}
.play_0{
    gap: 10px;
    display: flex;
}
