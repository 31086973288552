.detail_hero{
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    position: relative;
    border: 2px solid #ed1c23;
    max-width: 100%;
    border-radius: 3px;
    width: 100%;
    height:48px
}
.user.reg-0{
    gap:60px;
    display:flex;
    justify-content: center;
}
.detail_hero .regis-pro{
    border:none;
    padding: 10px 15px 10px 15px;
    outline-width: 0px;
    border-radius: 0px;
}
.detail_hero label{
    background: #ed1c23;
    height: 44px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: 700;  
}
.detail_hero .ant-input-affix-wrapper{
    border: none;
   font-size: 15px;
   margin-right: 5px;
}
.form-action-button button{
    padding: 13px 25px;
    font-weight: 600;
    color: #fff;
    width: 100%;
    border-radius: 3px;
    border: 2px solid #ed1c23;
    background: #ed1c23;
    font-size: 16px;
}
.user-re{
  border: 2px solid #ed1c23;;
    padding: 30px;
   
}
.form-action-button-0 button{
    padding: 13px 30px;
    font-weight: 600;
    color:#333333;
    width: 100%;
    border-radius: 3px;
    border: 2px solid #ed1c23;
    font-size: 16px;
    background: white;
}