.sec-bg {
    padding: 20px;
    border-radius: 144px;
    padding-bottom: 40px;
    text-align: center;
    margin-bottom: 20px;
}
.sec-bg h3 {
    font-size: 22px;
    margin-top: 10px;
    font-weight: 600;
}
.sec-bg p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #242424;
    margin-bottom: 15px;
}
.lazyload-0{
    width:100%
}
.sec-bg a{
    color: black;
    text-decoration: none;
    border: 1px solid black;
    padding: 8px;
    border-radius: 5px;
}
.form-control {
    border: 0px!important;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    outline-width: 0px;
}

.partner-wrapper {
    max-width: 1000px !important;
    padding:21px;
}