@import url('https://fonts.googleapis.com/css2?family=Aclonica&family=Cairo:wght@200;300;400;500;600;700;800;900&family=Kanit:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oswald:wght@200;300;400;500;600&family=Poppins:ital,wght@0,100;0,800;0,900;1,100;1,800&family=Roboto:ital,wght@0,700;0,900;1,100;1,500&display=swap');
*{
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  font-family: "Cairo",sans-serif;
}
iframe{
  /* position: fixed !important; */
  /* height:unset !important; */
  top: unset !important;
  left:unset !important;
}
