.categiries-banner {
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.banner-slider {
  background-color: #f7f7f7;
  padding: 50px 0px;
}

.image-fluid.cate {
  display: block;
  width: 190px;
  height: 190px;
  border-radius: 50%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #13181a8f;
}

.categiries-banner:hover .overlay {
  opacity: 1;
  cursor: pointer;
}

.text {
  color: white;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Poppins", sans-serif;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.react-multi-carousel-item {
  display: flex;
  justify-content: center;
}

.react-multiple-carousel__arrow {
  background: #ed1c23 !important;
  height: 30px !important;
  width: 30px !important;
  z-index: 1 !important;
}

.single-product-wrap .product-image {
  position: relative;
  cursor: pointer;
}

.single-product-wrap .product-image .product-label .sale-title {
  position: absolute;
  top: 5px;
}

.single-product-wrap .product-image .product-label .sale-title {
  right: 5px;
}

.product-label .sale-title {
  background-color: #ed1c23;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20% 50%);
}

.product-label .sale-title {
  font-size: 13px;
  padding: 2px 5px 2px 15px;
  color: #fff;
}

.single-product-wrap .product-image .product-action {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}

.action-wishlist {
  display: flex;
  flex-direction: row;
}

.add-wishlist {
  opacity: 0;
  font-size: 16px;
  line-height: 0;
  height: 40px;
  width: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin-right: 15px;
}

.product-image:hover .add-wishlist {
  opacity: 1;
  cursor: pointer;
}

.product-content .product-title a {
  font-weight: 600;
  color: black;
  text-decoration: none;
}

.new-price {
  font-size: 16px;
  font-weight: 600;
}

.old-price {
  font-size: 16px;
  text-decoration: line-through;
  padding-left: 5px;
}

/* .single-product-wrap {
  box-shadow: rgb(1 164 85 / 20%) 0px 0px 5px 0px,
    rgb(1 162 82 / 39%) 0px 0px 1px 0px;
  border-radius: 5px;
} */
.single-product-wrap:hover{
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;	
}

.product-content {
  padding: 0px 10px 5px;
}

.product_hero ul {
  display: flex;
  list-style: none;
  justify-content: center;
}

.product_hero ul li {
  margin-right: 30px;
  padding: 8px 15px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #949494;
}

.product_hero ul li:hover {
  border: 1px solid #3d459a;
  background-color: #3d459a;
  color: white;
  border-radius: 50px;
  cursor: pointer;
}

.active-tab {
  border: 1px solid #3d459a;
  color: white !important;
  border-radius: 50px;
  cursor: pointer;
  background-color: #3d459a;
}

.pro-quick {
  display: flex;
}

.pro-quick .quick-view {
  width: 50%;
}

.ant-modal {
  width: 651px !important;
}

.hero_quick .product-title a {
  font-size: 16px;
  color: #333333;
}

.more-de .simple-0 {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.more-de .lorem-09 {
  color: #8e8e8e;
}

.selector-wrapper label {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.single-option-selector {
  margin-left: 8px;
  padding: 8px 30px 8px 15px;
  border: 1px solid #eeeeee;
  border-radius: 50px;
  text-transform: uppercase;
}

.cart-plus-minus {
  max-width: 112px;
  display: flex;
  border: 1px solid #eee;
  width: 105px;
}

.dec.qtybutton {
  height: 30px;
  width: 30px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inc.qtybutton {
  height: 30px;
  width: 30px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.cart-plus-minus input{
  height: 30px;
    width: 50px;
    font-size: 13px;
    padding: 0;
    text-align: center;
    border: none;
    border-radius: 0;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    outline-width: 0px;
}
.product-action-2 .action-wishlist .add-wishlist{
  opacity: 1;
    background: #ed1c23;
    color: white;
  font-size: 16px;
    line-height: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
}
 .ant-modal-footer{
  display: none!important;
}
.ant-modal-header{
  border-bottom: 1px solid #8f8f8f8f;
}